@charset "utf-8";

//
// Variables and mixins that are shared between the global CSS and CSS modules
//
@import '../../../../../scss/functions';
@import '../../../../../scss/config';
@import '../../../../../scss/mixins';
@import '../../../../../scss/vars';
@import '../../../../../scss/media_queries';

.modalContainer {
  position: fixed;
  z-index: 21; // On top of Trusted Shops badge
  bottom: 20px;
  left: 20px;
  background: $paper;
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.35);
  max-height: 90%;
  overflow-y: auto;
}

.modalTrigger {
  position: fixed;
  z-index: 10;
  bottom: 10px;
  left: 10px;
  background: $paper;
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.35);
  padding: 12px 64px 12px 30px;

  &:hover {
    cursor: pointer;

    .modalTitle {
      color: $black;
    }

    .modalTriggerClose svg * {
      stroke: $black;
    }
  }
}

.modalTriggerClose {
  height: 26px;
  width: 26px;
  top: 13px;
  right: 22px;
  position: absolute;
  z-index: 1;

  svg {
    height: 26px;
    width: 26px;

    * {
      stroke: $charcoal;
      stroke-width: 6;
      transition: stroke 0.2s ease-in-out;
    }
  }
}

.modal {
  bottom: 0;
  left: 0;
  overflow-x: hidden;
  overflow-y: auto;
  outline: none;
  padding: 18px;
  position: fixed;
  right: 0;
  top: 0;
  -webkit-overflow-scrolling: touch;
  display: flex;
}

.modalTitle {
  @include subheader-font-demi;
  letter-spacing: rem(2);
  text-transform: uppercase;
  color: $charcoal;
  transition: color 0.2s ease-in-out;
  padding-right: 30px;
}

.modalContent {
  min-height: 140px;
  background-color: $paper;
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.35);
  max-width: 836px;
  margin: auto;
  padding: 23px 30px 30px;
  position: relative;

  @include min-screen(768px) {
    padding: 13px 20px 15px;
  }
}

.modalText {
  @include body-font-4;
  color: $charcoal;
  margin-top: 15px;

  p {
    line-height: 15px;
  }

  a {
    @include body-font-5;
    color: $charcoal;
    border-bottom: 1px solid $charcoal;
    transition: color 0.2s ease-in-out, border-color 0.2s ease-in-out;

    &:hover {
      color: $black;
      border-color: $black;
    }
  }

  @include max-screen(767px) {
    @include body-font-2;

    a {
      @include body-font-3;
    }
  }
}

.modalOverlay {
  position: relative;
  z-index: 101;

  &:before {
    background: rgba(55, 55, 55, 0.7);
    content: '';
    display: block;
    height: 100%;
    left: 0;
    min-height: 100%;
    position: fixed;
    top: 0;
    width: 100%;
  }
}

.modalCloseButton {
  height: 26px;
  width: 26px;
  top: 22px;
  right: 24px;
  position: absolute;
  z-index: 1;

  &:hover {
    svg * {
      stroke: $black;
    }
  }

  svg {
    height: 26px;
    width: 26px;

    * {
      stroke: $charcoal;
      stroke-width: 6;
      transition: stroke 0.2s ease-in-out;
    }
  }

  @include min-screen(768px) {
    top: 12px;
    right: 14px;
  }
}
