@charset "utf-8";

//
// Variables and mixins that are shared between the global CSS and CSS modules
//
@import '../../../../../scss/functions';
@import '../../../../../scss/config';
@import '../../../../../scss/mixins';
@import '../../../../../scss/vars';
@import '../../../../../scss/media_queries';

.container {
  background: $mx-color-white;
  position: absolute;
  z-index: 10;
  max-width: 1000px;
  min-width: 700px;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.header {
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  height: 430px;
  position: relative;

  .closeButton {
    position: absolute;
    z-index: 11;
    top: 20px;
    right: 20px;
    width: 30px;
    height: 30px;

    svg {
      display: block;
      width: 30px;
      height: 30px;

      path {
        stroke: $mx-color-white;
        stroke-width: 2;
      }
    }
  }
}

.content {
  background: $mx-color-white;
  padding: 48px 20% 35px;
  text-align: center;

  .subtitle {
    @include h3-font-demi;
    color: $charcoal;  
  }

  .info {
    @include body-font-3;
    margin: 40px 0 0;
    text-align: left;
  }

  .form {
    label {
      @include body-font-5;
      display: block;
      text-align: left;
      margin-bottom: 0;
      color: $ash;

      input {
        margin-top: 7px;
      }
    }

    .button {
      width: 60%;
      margin: 0 auto;
    }
  }
}

.footer {
  .footNote {
    @include body-font-5;
    margin: 0 58px 16px;
    text-align: left;
    color: $neutral;
  }
}
