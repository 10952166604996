@charset "utf-8";

//
// Variables and mixins that are shared between the global CSS and CSS modules
//
@import '../../../../../scss/functions';
@import '../../../../../scss/config';
@import '../../../../../scss/mixins';
@import '../../../../../scss/vars';
@import '../../../../../scss/media_queries';

$grey: #f0f0f0;

.collapsingPanel {
  background-color: $mx-color-grey-6;
  margin-bottom: 6px;
  position: relative;
}

.iconContainer {
  height: 30px;
  position: absolute;
  right: 20px;
  top: 20px;
  width: 30px;
  z-index: 1;

  svg {
    display: block;
    height: 30px;
    width: 30px;
    @include transform(rotate(180deg));
    transition: transform 0.2s;
  }

  @include max-screen(367px) {
    right: 8px;
    top: 17px;
  }
}

.content {
  @include mx-font-body-2;
  color: $mx-color-grey-2;
  margin: 0;
  overflow: hidden;
  padding: 0 25px 25px;
  position: relative;

  ul {
    list-style-type: none;

    li {
      display: flex;
      font-size: rem(13);

      &:before {
        content: '•';
        margin-right: 9px;
      }
    }
  }

  @include max-screen(367px) {
    padding: 0 12px 25px;
  }
}

.headlineButton {
  color: $mx-color-grey-2;
  cursor: pointer;
  border: none;
  display: block;
  width: 100%;
  text-align: left;
  padding: 0;
  outline: none;
  background: none;
}

.headline {
  @include body-font-2;
  padding: 25px 70px 25px 25px;
  margin: 0;
  transition: color 0.2s linear;
  display: flex;

  @include max-screen(992px) {
    font-size: rem(14);
    line-height: 1.79;
    padding: 23px 52px 23px 15px;
  }

  @include max-screen(767px) {
    padding-top: 10px;
  }
}

@keyframes slideDown {
  0% {
    max-height: 0;
  }
  100% {
    max-height: 3000px;
  }
}

.open {
  .headline {
    color: $mx-color-primary;
  }

  .iconContainer {
    svg {
      @include transform(rotate(0deg));
    }
    path {
      stroke: $mx-color-primary;
      stroke-width: 4;
    }
  }
}

.small {
  background: none;
  margin-bottom: 10px;

  @include max-screen(991px) {
    margin-bottom: 15px;
  }

  .headlineButton {
    background-size: 9px 9px;
    background-image: linear-gradient(
      45deg,
      $grey 48%,
      $ice 49%,
      $ice 51%,
      $grey 52%
    );

    @include max-screen(991px) {
      background-image: linear-gradient(
        45deg,
        transparent 48%,
        $ice 49%,
        $ice 51%,
        transparent 52%
      );
    }
  }

  .headline {
    @include button-cta-font;
    color: $ash;
    padding: 10px 32px 9px 10px;
    text-transform: uppercase;

    @include max-screen(991px) {
      padding-left: 12px;
    }
  }

  .iconContainer {
    height: 20px;
    right: 10px;
    top: 6px;
    width: 20px;

    svg {
      height: 20px;
      width: 20px;

      path {
        stroke: $ash;
      }
    }
  }

  &.open {
    .headlineButton {
      background: $grey;

      @include max-screen(991px) {
        background: $paper;
      }
    }

    .headline {
      color: $ash;
    }

    path {
      stroke: $ash;
      stroke-width: 3;
    }

    .content {
      background: $grey;
      padding-top: 8px;

      @include max-screen(991px) {
        background: $paper;
      }
    }
  }
}

.center {
  background: none;
  margin: 10px 0;

  .content {
    padding: 0;
  }

  .headlineButton {
    border: 1px solid $grey-sky;
  }

  .headline {
    @include mx-font-subheading;
    color: $ash;
    font-size: rem(14);
    letter-spacing: rem(0.6);
    padding: 6px;
    text-align: center;
  }

  .iconContainer {
    height: 26px;
    right: 8px;
    top: 8px;
    width: 26px;

    svg {
      height: 26px;
      width: 26px;

      path {
        stroke: $grey-sky;
        stroke-width: 3;
      }
    }
  }
}

.transparent {
  margin-bottom: 0;
  background: transparent !important;

  .headline {
    font-size: rem(14);
  }

  .headlineButton {
    background: transparent !important;
    padding: 5px 0px;
  }

  .iconContainer {
    top: 14px !important;
    right: 14px !important;

    svg {
      transition: transform 0.4s cubic-bezier(0.5, 0, 0, 0.5);
      transform: rotateX(0deg);
      transform-origin: center;
    }
    path {
      stroke: $mx-color-white !important;
      stroke-width: 3;
    }
  }

  &.open {
    .iconContainer {
      svg {
        transform: rotateX(180deg);
      }
    }
  }
}

.white {
  &.open {
    .headlineButton,
    .content {
      background: $white;
    }
  }
}

.configuratorLayout {
  margin-bottom: 0;
  background-color: $white;
  border-bottom: 1px solid $ice;

  .headline {
    @include body-font;
    color: $charcoal;
    padding: 8px 70px 8px 30px;
  }

  .headlineButton {
    padding: 5px 0px;
  }

  .content {
    padding: 0 4px 20px;
  }

  .iconContainer {
    top: 15px !important;
    right: 25px !important;
    width: 20px;
    height: 20px;

    svg {
      width: 20px;
      height: 20px;
      transition: transform 0.3s cubic-bezier(0.5, 0, 0, 0.5);
      transform: rotateX(0deg);
      transform-origin: center;
    }

    path {
      stroke: $petrol-green !important;
      stroke-width: 6;
    }
  }

  &.open {
    background-color: $paper;

    .iconContainer {
      svg {
        transform: rotateX(180deg);
      }
    }
  }

  &.withoutSideMargins {
    .content {
      padding: 0 0 20px;
    }
  }
}

.infoCollapsingPanel {
  width: 100%;
  margin: 0;
  background-color: $paper;
  vertical-align: middle;

  .headlineButton {
    padding: 0;

    .infoIcon {
      padding-left: 10px;
      margin: 0 5px 0 -3px;
      height: 20px;
      vertical-align: middle;

      * {
        stroke: $ash;
        stroke-width: 3;
      }
    }
  }

  .headline {
    font-size: rem(12);
    line-height: rem(20);
    letter-spacing: rem(0.56);
    color: $ash;
    display: inline-block;
    padding: 10px 0;
    vertical-align: middle;
  }

  .iconContainer {
    top: 11px;
    right: 10px;
    width: 18px;
    height: 18px;

    svg {
      height: 18px;
      width: 18px;
      transition: transform 0.4s cubic-bezier(0.5, 0, 0, 0.5);
      transform: rotateX(0deg);
      path {
        stroke: $ash;
        stroke-width: 7;
      }
    }

    @include min-screen(993px) {
      display: none;
    }
  }

  :global(.Collapsible__content) {
    display: none;
  }

  &.open {
    .headlineButton {
      border-bottom: 1px solid $grey-sky;
    }

    .iconContainer {
      svg {
        transform: rotateX(180deg);
      }
    }

    .content {
      padding: 13px 10px;
      font-size: rem(13);
      line-height: rem(23);
    }

    :global(.Collapsible__content) {
      display: block;
    }
  }
}

.panelLabel {
  color: $petrol-green;
  @include body-font-6;
  padding-left: 5.5px;
  line-height: 2rem;
}

.clarifyingTextContainer {
  margin-top: 1rem;
  margin-bottom: 2.2rem;
}

.blackText .headlineButton .headline {
  color: $black;
}

.blackText.small {
  &.open {
    .content {
      background: $light-beige;
      padding-top: 8px;

      @include max-screen(991px) {
        background: $light-beige;
      }
    }
  }
}

.lightCompact.collapsingPanel {
  @include max-screen(767px) {
    border-bottom: 1px $light-ice solid;
  }

  .headlineButton {
    padding: 14px 0;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .headline {
      background-color: $white;
      padding: 0;
    }

    .iconContainer {
      position: inherit;
    }
  }

  .content {
    padding: 10px 0;
  }
}
