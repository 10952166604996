@charset "utf-8";

//
// Variables and mixins that are shared between the global CSS and CSS modules
//
@import '../../../../../scss/functions';
@import '../../../../../scss/config';
@import '../../../../../scss/mixins';
@import '../../../../../scss/vars';
@import '../../../../../scss/media_queries';

.banner {
  background-color: $mx-color-grey-6;
}

.bannerLogo {
  svg {
    path {
      fill: $mx-color-grey-2;
    }

    polygon {
      fill: $mx-color-grey-2;
    }
  }
}

.footerMenu {
  background: $ash;
  position: relative;
  z-index: 0;
  padding: 78px 0 10px;
  @include max-screen(1280px) {
    margin: 0;
  }
  @include max-screen(767px) {
    padding: 10px 10px 70px;
    overflow: auto;
  }

  h3 {
    color: $mx-color-white !important;
  }

  span {
    color: $mx-color-white;
  }

  a {
    color: $mx-color-white;

    &:hover,
    &:focus {
      color: $mx-color-white;
    }
  }
}

:global(.checkoutFooter) {
  padding: 60px 120px;

  [class^='MenuGroups__group']:not([class^='MenuGroups__groupHeading']) {
    padding: 0;
    width: 27%;
    order: 1;

    img {
      width: auto;
      height: auto;
      min-width: 45px;
      max-height: 45px;
    }
  }

  [class^='MenuGroups__container'] {
    max-width: 1220px;
    gap: 40px;
  }

  [class^='MenuGroups__item'] {
    margin-bottom: 10px;
    margin-right: 24px !important;
  }

  [class^='group_W88wbC3eCIkwc6MwqqgGe'] {
    ul {
      margin-top: 0;
    }
  }

  @include max-screen(1280px) {
    [class^='MenuGroups__container'] {
      margin: 0;
    }
  }

  @include max-screen(1024px) {
    padding: 40px 40px 20px;

    [class^='MenuGroups__container'] {
      gap: 0;
      justify-content: space-between;
    }
  }

  @include max-screen(767px) {
    padding: 40px 0 20px;

    [class^='MenuGroups__container'] {
      display: block;
      margin: auto;
    }

    [class^='MenuGroups__group']:not([class^='MenuGroups__groupHeading']) {
      padding: 0px 15px 25px !important;
      width: 100%;
      text-align: left;
    }

    [class^='MenuGroups__group']:not(
        [class^='MenuGroups__groupHeading']
      ):last-child {
      padding: 0px 135px 30px 15px;
    }

    [class^='MenuGroups__item'] {
      margin: 5px 28px 10px !important;
    }

    [class^='MenuGroups__link'] {
      padding: 10px 0px !important;
    }

    // Payment methods group
    [class*='MenuGroups__group_W88wbC3eCIkwc6MwqqgGe'] {
      display: block;
    }
  }
}
