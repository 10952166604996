@charset "utf-8";

//
// Variables and mixins that are shared between the global CSS and CSS modules
//
@import '../../../../../scss/functions';
@import '../../../../../scss/config';
@import '../../../../../scss/mixins';
@import '../../../../../scss/vars';
@import '../../../../../scss/media_queries';

.alert {
  opacity: 0;
  height: 0;
  padding: 0;
  animation: alertAnim 8s;
}

@mixin alert($opacity, $height, $padding) {
  opacity: $opacity;
  height: $height;
  padding: $padding;
}

@keyframes alertAnim {
  0% {
    @include alert(0, 0, 0);
  }
  10% {
    @include alert(1, 60px, 13px);
  }
  95% {
    @include alert(1, 60px, 13px);
  }
  100% {
    @include alert(0, 0, 0);
  }
}
