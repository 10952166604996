@charset "utf-8";

//
// Variables and mixins that are shared between the global CSS and CSS modules
//
@import '../../../../../scss/functions';
@import '../../../../../scss/config';
@import '../../../../../scss/mixins';
@import '../../../../../scss/vars';
@import '../../../../../scss/media_queries';

$bottomMenuHeight: 60px;

.container {
  &.opened {
    .menuOverlay {
      display: block;
    }
    .menu {
      @include animation(menuTranslateRight 0.3s linear);
      animation-fill-mode: forwards;
    }
  }

  &.closing {
    .menu {
      @include animation(menuTranslateLeft 0.3s linear);
      animation-fill-mode: forwards;
    }
  }

  &.closed {
    .menuOverlay {
      display: none;
    }
  }

  .menuOverlay {
    background-color: rgba(55, 55, 55, 0.5);
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    overflow: hidden;
    z-index: 17;

    &.opened {
      display: block;
    }

    &.closed {
      display: none;
    }
  }

  .menu {
    display: block;
    background-color: $white;
    width: 90%;
    height: 1000px;
    animation-fill-mode: forwards;

    &.opened {
      @include animation(menuTranslateRight 0.3s linear);
    }

    &.closing {
      @include animation(menuTranslateLeft 0.3s linear);
    }

    &.closed {
      display: none;
    }

    @include mobile(landscape) {
      width: 100%;
    }
  }

  .menuButton {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 40px;
    height: 40px;
    padding: 3px 5px;

    &.mobileConfigurator {
      align-items: center;
      padding: 2px 4px;
    }

    &:hover {
      background: $light-ice;
    }
  }

  .menuButtonIcon {
    width: 27px;
    height: 2px;
    background-color: $charcoal;
    margin: 6px 1px;
  }

  .menuButtonText {
    @include body-font-6;
    color: $charcoal;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  .scrolling {
    height: calc(100% - #{$bottomMenuHeight});
    overflow-x: hidden;
    overflow-y: auto;

    @include mobile(landscape) {
      height: 600px;
    }
  }

  li {
    border-top-color: $ice !important;
  }

  [class^='MenuGroups__group'] {
    border-bottom-color: $ice !important;
  }
}

@keyframes menuTranslateLeft {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-100%);
  }
}

@keyframes menuTranslateRight {
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(0);
  }
}
