/* ################# Styles for inputs ################# */
input,
textarea {
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
  background-image: none;
  outline: 0;

  @include input-placeholder {
    color: $mx-color-black;
    font-size: em($base-font-size) !important;
  }

  &:focus {
    outline: 0;

    @include input-placeholder {
      color: $mx-color-black;
    }
  }
}

input[readonly='readonly'],
input[readonly] {
  // To be customized
}

input[disabled='disabled'],
input[disabled] {
  // To be customized
}

label {
  cursor: pointer;
}

textarea {
  resize: vertical;
}
