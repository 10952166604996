@charset "utf-8";

//
// Variables and mixins that are shared between the global CSS and CSS modules
//
@import '../../../../../scss/functions';
@import '../../../../../scss/config';
@import '../../../../../scss/mixins';
@import '../../../../../scss/vars';
@import '../../../../../scss/media_queries';

.container {
  background-color: $mx-color-white;
  bottom: 0;
  right: 0;
  z-index: 1;
  box-shadow: 0 4px 7px 3px rgba(0, 0, 0, 0.2);

  @include max-screen(1024px) {
    right: 1%;
  }

  @include min-screen(768px) {
    display: block;
    position: fixed;
    width: 610px;
    max-height: 480px;
  }

  &.slimLayout {
    @include min-screen(768px) {
      height: 300px;
      width: 280px;
      background-color: $mx-color-white;

      .options {
        display: block;

        .icon {
          display: block;
        }

        .option {
          float: none;
          clear: both;
          display: block;
          margin-bottom: 24px;
          width: 100%;
        }
      }
    }
  }

  @include max-screen(767px) {
    bottom: 0;
    left: 0;
    margin: 0;
    position: fixed;
    right: 0;

    .miniFaq {
      display: none;
    }
  }
}

.closeIcon {
  display: block;
  position: absolute;
  right: 10px;
  top: 15px;

  svg {
    height: 25px;
    width: 25px;

    path {
      stroke: $mx-color-white;
      stroke-width: 4;
    }
  }

  &:hover path {
    stroke: $neutral;
  }
}

.header {
  background: $charcoal;
  padding: 12px 10px;
  position: relative;
  text-align: center;
}

.title {
  color: $mx-color-white;
  font-family: $secondary-font-family;
  font-size: rem(16);
  letter-spacing: 0.6px;
  text-align: center;
}

.options {
  background: $mx-color-white;
  min-height: 100px;
  padding: 25px 0;

  @include max-screen(767px) {
    display: block;
    padding: 32px 20px 20px;
  }
}

.option {
  padding: 0 25px;
  text-align: left;
  line-height: normal;

  &.backButton {
    .backButtonContent {
      text-align: right;
    }

    em {
      text-align: right;
    }

    .icon {
      display: block;
      float: right;
      width: 25px;
      height: 25px;
      margin: 0;

      svg {
        width: 25px;
        height: 25px;
      }
    }
  }

  @include min-screen(768px) {
    float: left;

    &[class^='Button__button'][class*='Button__disabled'] {
        opacity: 1.0;
      }
    
    &[disabled] {
      cursor: default;
      pointer-events: none;

      &:hover {
        .icon {
          border-color: transparent;
        }
        em,
        small {
          color: $ash;
        }
      }
    }
  }

  @include max-screen(767px) {
    float: none;
    clear: both;
    display: block;
    margin-bottom: 24px;
    width: 100%;
  }

  &:hover {
    .icon {
      border-color: $neutral;
    }

    em,
    small {
      color: $pale-gold;
    }
  }

  .icon {
    float: left;
    margin: 8px 25px 0 0;
    height: 35px;
    width: 35px;

    svg {
      height: 35px;
      width: 35px;
    }

    @include max-screen(767px) {
      display: block;
    }
  }

  .inlineIcon {
    display: inline-block;
    float: none;
    margin: 0;
    width: 25px;
    height: 25px;

    svg {
      width: 25px;
      height: 25px;

      path,
      circle {
        stroke: $pale-gold;
      }
    }

    @include max-screen(767px) {
      display: none;
    }
  }

  em {
    @include mx-font-title;
    color: $ash;
    display: block;
    font-size: rem(12);
    font-style: normal;
    letter-spacing: 1px;
    line-height: 1.42;
    padding-top: 7px;
    text-align: left;
  }

  small {
    @include mx-font-quote-caption;
    color: $pale-gold;
    text-align: left;
    text-wrap: nowrap;
  }

  &:hover {
    small {
      color: $pale-gold;
    }
  }
}

.hidden {
  display: none;
}