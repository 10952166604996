// --------------------------------------------------
// Vocabulary mixins (for BEM and namespacing)
//---------------------------------------------------
@mixin block($name, $type: null) {
  @at-root {
    @if $type != null {
      .#{$name}-#{$type} {
        /* */
        @content;
      }
    } @else {
      .#{$name} {
        /* */
        @content;
      }
    }
  }
}

@mixin if-its($state) {
  &.is-#{$state} {
    /* */
    @content;
  }
}

@mixin holds($something) {
  &.holds-#{$something} {
    /* */
    @content;
  }
}

@mixin element($name) {
  @at-root #{&}__#{$name} {
    /* */
    @content;
  }
}

@mixin modifier($name) {
  @at-root #{&}--#{$name} {
    /* */
    @content;
  }
}
