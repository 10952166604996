@charset "utf-8";

//
// Variables and mixins that are shared between the global CSS and CSS modules
//
@import '../../../../../scss/functions';
@import '../../../../../scss/config';
@import '../../../../../scss/mixins';
@import '../../../../../scss/vars';
@import '../../../../../scss/media_queries';

.container {
  bottom: 0;
  position: fixed;
  right: 10%;
  z-index: 10;

  &.isCentered {
    left: 50%;
    transform: translateX(-50%) !important;
    text-align: center;
  }

  &.sticky {
    bottom: 0;
  }

  @include max-screen(1024px) {
    right: 1%;
  }

  @include max-screen(767px) {
    right: 0;
    height: 90px;
    padding: 15px;

    &.isOpenHelp {
      width: 100%;
    }
  }

  .desktopToggle {
    font-size: rem(16);
    letter-spacing: 0.6px;
    padding: 10px 0;
    min-width: 264px;
    z-index: 0;
    text-transform: none;
    @include box-shadow(0 -1px 10px 0 rgba(0, 0, 0, 0.3));

    @include max-screen(767px) {
      display: none;
    }

    @include mobile(all) {
      display: none;
    }
  }

  .mobileToggle {
    border-radius: 50%;
    height: 60px;
    min-height: 60px;
    padding: 0;
    width: 60px;
    margin-left: auto;
    display: none;

    svg {
      margin-top: 5px;
    }

    @include max-screen(767px) {
      display: block;
    }

    @include mobile(all) {
      display: block;
    }
  }
}
