@charset "utf-8";

//
// Variables and mixins that are shared between the global CSS and CSS modules
//
@import '../../../../../scss/functions';
@import '../../../../../scss/config';
@import '../../../../../scss/mixins';
@import '../../../../../scss/vars';
@import '../../../../../scss/media_queries';

.modalContent {
  padding: 0;
}

.form {
  padding: 23px 0 0;
  margin: 0 auto;
  font-size: rem(16);
  max-width: 371px;
  min-height: 601px;

  .textArea {
    width: 100%;
  }

  .submit {
    display: block;
    margin: 0 auto 30px;

    &:focus {
      background-color: $mx-color-primary-dark;
      border-color: $mx-color-primary-dark;
    }
  }

  @include max-screen(768px) {
    padding: 30px 0 0;
  }

  @include max-screen(767px) {
    padding: 30px 20px 0;
  }
}

.responseScreen {
  background: url('/img/contact/wood-texture.jpg') center bottom;
  background-size: cover;

  .messageWrapper {
    text-align: center;
    padding: 0 30px;
  }

  .form {
    display: flex;
    align-items: center;
    justify-content: center;
    max-width: none;
  }

  h5 {
    @include h3-font-demi;
    margin-bottom: 10px;
  }

  p {
    @include body-font-2;
    color: $charcoal;
    margin-bottom: 50px;
  }
}

.textPanelContainer {
  padding: 0;
  position: relative;
  text-align: left;

  .panelText {
    @include body-font-2;
    color: $charcoal;
    margin: 0 0 15px;
  }

  .panelTextBig {
    font-size: rem(18);
    margin-top: 35px;
  }

  .phoneNumber {
    @include mx-font-quote;
    color: $charcoal;
  }
}

.bottom {
  @include body-font-2;
  background: $mx-color-grey-6;
  border-bottom: 1px solid $mx-color-grey-3;
  color: $charcoal;
  margin-bottom: 0;
  min-height: 55px;
  padding: 22px 28px;

  @include max-screen(768px) {
    text-align: left;
  }
}
