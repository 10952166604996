@charset "utf-8";

@import 'print';

@import 'cookiebot';

// Reset tags for browser compatibility
article,
aside,
details,
figure,
footer,
header {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent;
}

math {
  display: none;
}

html {
  font-size: 10px;
  // fix thick fonts in firefox
  -moz-osx-font-smoothing: grayscale;
}

html,
body {
  width: 100%;
  height: 100%;
}

html,
button {
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
}

body {
  background: $mx-color-white;
  color: $mx-color-grey-2;
  counter-reset: definition;
  font-size: rem(20);
  font-family: $base-font-family;
  letter-spacing: 0.33px;
  line-height: rem(32);
  overflow-x: hidden;

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin: 0;
    padding: 0;
    text-transform: none;
  }

  a,
  p,
  input,
  textarea,
  li {
    font-family: $base-font-family;
  }

  input,
  select {
    @include box-shadow(none);
  }

  input:focus::-webkit-input-placeholder {
    color: transparent !important;
  }

  input:focus::-moz-placeholder {
    color: transparent !important;
  }

  input:focus:-ms-input-placeholder {
    color: transparent !important;
  }

  input[type='number']::-webkit-inner-spin-button,
  input[type='number']::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  a,
  a:focus,
  a:active {
    color: $mx-color-black;
    cursor: pointer;
    will-change: color, text-decoration;
  }

  a:hover,
  a.active {
    cursor: pointer;
    text-decoration: none;
  }

  a:hover,
  a:focus {
    color: $mx-color-black;
  }

  a:focus {
    text-decoration: none;
  }

  p {
    line-height: $base-font-line-height;
  }

  b,
  strong {
    font-family: $secondary-font-family;
    font-weight: normal;
  }
}

button[disabled] {
  opacity: 0.3;
}

.row {
  margin-left: 0;
  margin-right: 0;
}

.container {
  padding: 0;
}

.container-fluid {
  @include no-side-padding;

  @include max-screen(435px) {
    overflow-x: hidden;
  }
}

.mirror-icon {
  @include transform(scale(-1, 1));
}

canvas {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

img {
  vertical-align: middle;
}

.disable-pointer-events {
  pointer-events: none;
}

// A must have for every project. IE8+
*,
*:before,
*:after {
  @include box-sizing(border-box);
}

.clickable {
  cursor: pointer;
}

.configurator-couchtable li.active a {
  cursor: default;
}

.no-clickable {
  opacity: 0.5;
  pointer-events: none;
}

// Nice to have
.hidden {
  display: none !important;
  visibility: hidden;
}

.v-hidden {
  visibility: hidden;
}

.v-hidden-all * {
  visibility: hidden !important;
}

.rel {
  position: relative;
}

.pntr {
  cursor: pointer;
}

// Extra column sizes
.col-md-4¾,
.col-md-2½ {
  position: relative;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px;

  @include min-screen(992px) {
    float: left;
  }
}

// 5 columns in a row
.col-md-2½ {
  @include min-screen(992px) {
    width: 20%;
  }
}

// 40% column
.col-md-4¾ {
  @include min-screen(992px) {
    width: 40%;
  }
}

// Clearfix
.col-sm-3-clearfix:nth-child(4n + 1) {
  @include max-screen(991px) {
    clear: left;
  }
}

.col-sm-4-clearfix:nth-child(3n + 1) {
  @include max-screen(991px) {
    clear: left;
  }
}

.col-sm-6-clearfix:nth-child(2n + 1) {
  @include max-screen(991px) {
    clear: left;
  }
}

.col-md-3-clearfix:nth-child(4n + 1) {
  @include min-screen(992px) {
    clear: left;
  }
}

.col-sm-4--clearfix:nth-child(3n) {
  @include max-screen(991px) {
    clear: left;
  }
}

.cf:before,
.cf:after {
  content: ' ';
  display: table;
}

.cf:after {
  clear: both;
}

.cf {
  *zoom: 1;
}

.o-vis {
  overflow: visible;
}

.o-hid {
  overflow: hidden;
}

.vp {
  &:before {
    content: '';

    @include inline-block;

    height: 100%;
    vertical-align: middle;
  }
}

.vc {
  @include inline-block;

  vertical-align: middle;
}

.inline {
  display: inline;
}

.ib {
  @include inline-block;
}

.tl {
  text-align: left;
}

.tc {
  text-align: center;
}

.tr {
  text-align: right;
}

.line-height-normal {
  line-height: 16px !important;
}

// This class is used on elements not correctly redrawing while scolling on iOS Devices
.repaint-fix {
  @include transform(translateZ(0));
}

// Used for special cases, android browser wraps text
.no-wrap {
  white-space: nowrap;
}

// Shorthand to float elements left
.l {
  float: left;
}

// Shorthand to float elements right
.r {
  float: right;
}

// List
ul {
  margin: 0;
  padding: 0;
}

// Section
section {
  position: relative;
}

.section-title {
  padding: 33px 0px;
}

// Paragraph
p {
  line-height: em(20px);
}

// Width-aligned content
.contentContainer {
  margin-left: auto;
  margin-right: auto;
  max-width: 1220px;

  @include max-screen(1280px) {
    margin-right: 30px;
    margin-left: 30px;
  }

  @include max-screen(767px) {
    margin-right: 10px;
    margin-left: 10px;
  }
}

// Bootstrap
.row {
  width: 100%;
}

.content {
  max-width: 1200px;
  min-height: 100%;
  overflow-x: hidden;
  margin: 0 auto;

  @include box-shadow(rgba(0, 0, 0, 0.4) 0 0 4px 0);
}

@include screen(1200px, 1920px) {
  .container {
    width: 1200px;
  }
}

@include min-screen(1921px) {
  .container {
    width: 1200px;
  }
}

.no-padding-margin {
  padding: 0 !important;
  margin: 0 !important;
}

.no-padding {
  padding: 0 !important;
}

.no-margin {
  margin: 0 !important;
}

.no-margin-bottom {
  margin-bottom: 0 !important;
}

.no-margin-top {
  margin-top: 0 !important;
}

.margin-right-40 {
  margin-right: 40px !important;
}

.no-display {
  display: none !important;
}

.display {
  display: block !important;
}

.half-height {
  height: 50% !important;
}

.sm-half-height {
  @include ipad(portrait) {
    height: 50% !important;
  }
}

.xs-auto-height {
  @include screen(0px, 766px) {
    height: auto !important;
  }
}

.sm-auto-height {
  @include ipad(portrait) {
    height: auto !important;
  }
}

.height-inherit {
  height: inherit !important;
}

.height-auto {
  height: auto !important;
}

.thumbnail {
  border: none;

  .caption {
    padding-top: 16px;
  }
}

/* ################# Configurator  Globals ################# */

/* ### Elements ### */
.dot {
  width: 9px;
  height: 9px;
  display: inline-block;
  background: #d8dbe4;

  @include border-radius(50%);
  @include transition(background 100ms ease-in);

  will-change: background;
}

.dot-wrapper {
  a {
    &:hover,
    &.active {
      .dot {
        background: #00d8bb;
      }
    }

    .dot {
      will-change: background;
    }
  }

  li {
    display: inline-block;
    list-style: none;
  }
}

.config-add,
.remove-column-icon {
  background: $mx-color-black;
  cursor: pointer;
  border-radius: 50%;
  border: 3px solid $mx-color-white;
  height: 35px;
  margin-top: -17.5px;
  position: absolute;
  top: 50%;
  width: 35px;
  will-change: border-color, border;

  @include transition(border 100ms ease-in);

  &:hover {
    border-color: $mx-color-black;
  }
}

.config-add-left {
  margin-left: -35px;
}

.config-edit {
  cursor: pointer;
  height: 28px;
  position: absolute;
  width: 28px;

  @include box-shadow(rgba(0, 0, 0, 0.36) 0 1px 2px 0);
}

.custom-modal {
  background: $mx-color-white;
  padding: 40px 20px 20px 20px;
  max-width: 300px;
  position: absolute;
  text-align: center;
  z-index: 10;

  @include border-radius(4px);

  > a:first-child {
    position: absolute;
    right: 0;
    top: 0;
    line-height: 0;
    display: block;
    padding: 16px;

    @include opac-hover;
  }

  ul {
    a {
      padding: 4px;
    }
  }

  h2 {
    text-transform: uppercase;
  }
}

/* ### Font Size ### */
.f-x-large {
  font-size: em(24px);
}

.f-large {
  font-size: em(20px);
}

.f-small {
  font-size: em(14px);
}

.f-x-small {
  font-size: em(14px);
}

.uppercase {
  text-transform: uppercase;
}

/* ### Color ### */
.c-white {
  color: $mx-color-white;
}

.c-black {
  color: #000;
}

/* ### Background Color Images ### */
.bg-brand-black {
  background: $mx-color-black;
}

.bg-grey {
  background-color: $mx-color-grey-4;
}

/* ### Hacks ### */
.table-fix {
  &:before,
  &:after {
    content: initial;
  }
}

/*************** PRODUCTS MODULE ****************** */
.products-module {
  background: $mx-color-white;
  height: auto !important;
  outline: 0 solid $mx-color-white;
  padding: 0;

  div[class^='col-'],
  div[class*=' col-'] {
    padding: 0;
    margin: 0;
    height: 100%;
  }

  .recommendations {
    margin-bottom: 36px !important;
  }
}

/********** Content Page rules ****** */
.content-container {
  .text-container {
    margin: 30px 0;

    a,
    a:hover {
      color: $mx-color-secondary !important;
    }

    a:hover {
      border-bottom: 1px solid $mx-color-secondary;
    }
  }
}

#returns-container {
  font-size: 15px;
  line-height: 24px;

  p:nth-child(odd) {
    margin: 0;
  }

  p:nth-child(even) {
    margin-bottom: 28px;
  }
}

/* Sprite Icons */
.icon-s {
  background: url($sprite);

  &.lamp {
    background-position: 0 -951px;
    width: 44px;
    height: 48px;
  }

  &.box {
    background-position: (-45px) -951px;
    width: 49px;
    height: 44px;
  }

  &.cycle-arrows {
    background-position: (-95px) -951px;
    width: 48px;
    height: 49px;
  }

  &.shipping {
    background-position: (-151px) 0;
    width: 32px;
    height: 23px;
  }

  &.twitter {
    background-position: 0 -1010px;
  }

  &.pinterest {
    background-position: (-49px) -1010px;
  }

  &.facebook {
    background-position: (-98px) -1010px;
  }

  &.instagram {
    background-position: (-147px) -1010px;
  }

  &.houzz {
    background-position: (-196px) -1010px;
  }
}

.button {
  border-radius: 0;
  letter-spacing: 0.39px;
  line-height: normal;
  white-space: normal;

  @include transition(background 100ms ease-in);
}

/* Category filters and color picker colors */
.oak {
  background: $oak;
}

.beech {
  background: $beech;
}

.walnut {
  background: $walnut;
}

.oak_wenge_stain {
  background: $oak_wenge_stain;
}

.white_9003 {
  background: $white_9003;
}

.yellow_1021 {
  background: $yellow_1021;
}

.blue_5009 {
  background: $blue_5009;
}

.red_3016 {
  background: $red_3016;
}

.grey_7037 {
  background: $grey_7037;
}

.black_9004 {
  background: $black_9004;
}

.grey_7024 {
  background: $grey_7024;
}

.steel_black_9004 {
  background: $steel_black_9004;
}

.steel_stainless {
  background: $steel_stainless;
}

.veneered_white {
  background: $veneered_white;
}

.veneered_yellow {
  background: $veneered_yellow;
}

.veneered_blue {
  background: $veneered_blue;
}

.veneered_red {
  background: $veneered_red;
}

.veneered_grey {
  background: $veneered_grey;
}

.veneered_black {
  background: $veneered_black;
}

.veneered_anthracite {
  background: $veneered_anthracite;
}

// Override chat css
#livechat-compact-container,
#livechat-full {
  right: 95px !important;

  @include max-screen(700px) {
    display: none !important;
  }
}

.integration.pointer + div {
  display: none !important;
}

.no-padding-right {
  padding-right: 0 !important;
}

.no-padding-left {
  padding-left: 0 !important;
}

.show-on-phone {
  display: none;
}

@include mobile {
  .hide-on-phone {
    display: none !important;
  }

  .show-on-phone {
    display: block !important;
  }
}

.hide-on-medium-screen {
  @include max-screen(1024px) {
    display: none !important;
  }
}

.show-on-tablet-portrait {
  display: none;

  @include tablet(portrait) {
    display: block !important;
  }
}

.hide-on-Ipad-portrait {
  @include max-screen(1024px) {
    @include orientation(portrait) {
      display: none !important;
    }
  }
}

.hide-on-small-screen {
  @include max-screen(769px) {
    display: none !important;
  }
}

.show-on-small-screen {
  display: none;

  @include max-screen(769px) {
    display: block !important;
  }
}

.show-on-small-screen-inline {
  display: none;

  @include max-screen(769px) {
    display: inline-block !important;
  }
}

.show-on-mobile {
  display: none;

  @include max-screen(767px) {
    display: block !important;
  }
}

.hide-on-mobile {
  display: block;

  @include max-screen(767px) {
    display: none !important;
  }
}

.hide-on-mobile-inline {
  display: inline-block;

  @include max-screen(767px) {
    display: none !important;
  }
}

.line-separator {
  background-color: $mx-color-grey-4;
  padding: 0 15px;

  @include max-screen(769px) {
    padding: 0;
  }

  &:before {
    border-top: 1px solid $mx-color-grey-3;
    content: '';
    display: block;
  }
}

// Overrides bootstrap popover style
.popover.bottom > .arrow {
  border-bottom-color: $mx-color-white;
}

.popover.top > .arrow {
  border-top-color: $mx-color-white;
}

// Animations
@include keyframes(invalidfield) {
  0% {
    @include transform(translate(8px, 0px));
  }

  10% {
    @include transform(translate(-8px, 0px));
  }

  30% {
    @include transform(translate(8px, 0px));
  }

  60% {
    @include transform(translate(-8px, 0px));
  }

  100% {
    @include transform(translate(0px, 0px));
  }
}

@include keyframes(fade-in) {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

/* Remove scroll on the body when react-modal is open */
:global(.ReactModal__Body--open),
.body--no-scroll {
  overflow: hidden;
}

// Adyen overrides
.adyen-checkout__payment-method {
  border-radius: 0 !important;
  border-color: $light-ice;
}

.adyen-checkout__payment-method--selected {
  background-color: $paper;
}

.adyen-checkout__payment-method__header {
  line-height: 1;
  padding: 13px 16px 13px 48px;
  min-height: 60px;
}

.adyen-checkout__payment-method__name {
  @include mx-font-title;
  font-size: rem(16);
  line-height: rem(28);
  letter-spacing: rem(0.56);
  text-transform: capitalize;
  color: $ash;

  @include max-screen(374px) {
    font-size: rem(14);
  }
}

.adyen-checkout__label__text {
  @include mx-font-body-2;
  color: $ash;
}

.adyen-checkout__payment-method__radio {
  top: 0;
  bottom: 0;
  margin: auto;
}

.adyen-checkout__payment-method__brands {
  height: 26px;

  @include max-screen(374px) {
    height: 16px;
  }
}

.adyen-checkout__payment-method__brands
  .adyen-checkout__payment-method__image__wrapper,
.adyen-checkout__payment-method__brands img {
  height: 26px;
  width: 40px;

  @include max-screen(374px) {
    height: 16px;
    width: 24px;
  }
}

.adyen-checkout__payment-method__brands
  .adyen-checkout__payment-method__image__wrapper {
  margin-bottom: 0;
}

.adyen-checkout__payment-method__image {
  border-radius: 3px;
  vertical-align: baseline;
}

.adyen-checkout__payment-method__image__wrapper:after {
  display: none;
}

.adyen-checkout__icon {
  display: flex;
}

.adyen-checkout__input {
  border-radius: 0;
  @include mx-font-body-2;
  font-weight: normal;
  color: $charcoal;
}

.adyen-checkout__button {
  background: $petrol-green;
  color: $white;
  text-decoration: none;
  text-transform: uppercase;
  white-space: nowrap;
  border-radius: 0;
  font-family: $secondary-font-family;
  font-size: rem(14);
  letter-spacing: rem(2);
  line-height: rem(20);
  padding: 10px 20px;
  float: none;
  min-width: 80%;
  height: 40px;
  text-shadow: none;
  border: none;
  -webkit-transition: all 0.1s linear;
  -o-transition: all 0.1s linear;
  transition: all 0.1s linear;

  &__text {
    white-space: normal !important;
  }

  &:hover {
    background-color: $charcoal;
    box-shadow: none;
  }
}

.adyen-checkout__status {
  border-radius: 0;
  @include mx-font-title;
  letter-spacing: rem(2);
  line-height: rem(25);
  color: $ash;

  span {
    width: 100%;
  }
}

// Early bird tag

mark {
  @include body-font-4;
  color: $charcoal;
  background-color: $sand;
  padding: 2px 5px;
  position: absolute;
  margin-left: 6px;
}

div.Toastify__toast-container {
  width: auto;

  div.Toastify__toast.Toastify__toast--success {
    div.Toastify__toast-icon {
      svg {
        fill: $petrol-green;
      }
    }

    div.Toastify__progress-bar--success {
      background-color: $petrol-green;
    }
  }
}

div.Tooltip__popover.review-form-tooltip div.Tooltip__content {
  font-size: 14px;
  padding: 20px;
}

html:has(meta[property="og:url"][content="/ueber-uns"]),
html:has(meta[property="og:url"][content="/a-propos-de-nous"]) {
  #story-of-mycs-title{
    background-color: $dark-green;

    .TextBlock__container {
      @media (min-width: 1200px) {
          width: 1200px;
          padding: 116px 0 0;
        }
      @media (max-width: 768px) {
          padding: 64px 16px 0;
      }
    }

    div.TextBlock__defaultLayout.TextBlock__container{
      padding: 0;
      padding-bottom: 24px;
      margin-bottom: 0 !important;
    }

      div.TextBlock__htmlContentContainer.TextBlock__twoColumnsContainer {
        margin-bottom: 0 !important;
        column-gap: 56rem;
        @media (max-width: 768px) {
          div:nth-of-type(2) p {
            display: none;
          }
        }
      }
    
    p{
      color: $white;
      font-size: 16px;
      font-weight: 600;
      line-height: 25px;

      @media (max-width: 768px) {
        font-size: 16px;
      }

    }
  }

  #story-of-mycs-testimonials{
    background-color: $dark-green;

    .TextBlock__container {
      @media (min-width: 1200px) {
        width: 1200px;
        padding: 52px 0 30px;
      }

      @media (max-width: 768px) {
        padding: 30px 16px 0;
      }
    }

    div.TextBlock__defaultLayout.TextBlock__container{
      padding: 0;
      padding-bottom: 24px;
      margin-bottom: 0 !important;
    }

    div.TextBlock__htmlContentContainer.TextBlock__twoColumnsContainer {
      margin-bottom: 0 !important;
      column-gap: 56rem;

      > div:nth-of-type(2) { 
        text-align: end;

        @media (max-width: 768px) {
          text-align: start;
        }
      }
  
      p{
        color: $white;
        font-size: 16px;
        font-weight: 600;
        line-height: 25px;
      }
    }
  }

  #story-of-my-cs{
    .TextBlock__container {
      @media (min-width: 1200px) {
          width: 1200px;
          padding: 30px 0;
        }
      @media (max-width: 768px) {
          padding: 28px 16px 0;
      }

    }

    background-color: $dark-green;
    margin-bottom: 60px;
    margin-top: -2px;
    padding-top: 2px;
    overflow: auto;

    @media (min-width: 769px) {
      min-height: 480px;
    }

    @media (max-width: 768px) {
      margin-bottom: 0;
    }
    
      p{
        @media (max-width: 768px) {
          font-size: 28px;
          font-weight: 400;
          line-height: 38px;
          margin-bottom: 32px;
        }

        font-size: 36px;
        font-weight: 400;
        line-height: 48px;
        color: $white;
        max-width: 895px;
        u{
          color: $new-yellow;
        }
      }
  }

  #story-of-mycs-title-mobile{
    display: none;

    @media (max-width: 768px) {
      display: block;
      margin-top: -2px;
      padding-top: 2px;
      overflow: auto;
      background-color: $dark-green;
      margin-bottom: 20px;

      .TextBlock__container{
        padding: 0 16px 92px;
      }
      p{
        color: $white;
        font-size: 16px;
        font-weight: 600;
        line-height: 25px;
      }
    }
  }

  .Video__container{
    .Video__overlay {
      display: none;
    }

    @media (max-width: 768px) {
      margin-right: 16px;
      margin-left: 16px;
    }
  }

  .contentContainer {
    max-width: 1200px;
  }

  #today-mycs-remains{
    h3{
      max-width: 915px;
      height: auto;
      font-size: 32px;
      font-weight: 600;
      line-height: 45px;
      color: $charcoal;
      
      @media (max-width: 768px) {
          font-size: 24px;
          font-weight: 600;
          line-height: 34px;
      }
    }

    .TextBlock__container {
      @media (min-width: 1200px) {
        width: 1200px;
        padding: 0 0 10px 0;
      }

      @media (max-width: 768px) {
        padding: 30px 16px 0;
      }
    }
  }

  #build-own-story-with-us{
    .TextBlock__container {
      @media (min-width: 1200px) {
        width: 1200px;
        padding: 0;
      }

      @media (max-width: 768px) {
        padding: 30px 16px 0;
      }
    }

    h6{
      font-size: 16px;
      line-height: 26px;
      font-weight: 600;
      color: $charcoal;
    }
  }

  #about-us-design-future{
    background-color: $new-yellow;

    .TextBlock__container {
      @media (min-width: 1200px) {
        width: 1200px;
        padding: 30px 0 10px;
      }
      @media (max-width: 768px) {
        padding: 30px 16px 0;
      }
    }

    h3{
      @media (max-width: 768px) {
        font-size: 24px;
        line-height: 34px;
        font-weight: 600;
        
      }
      color: $charcoal;
      font-size: 32px;
      line-height: 44px;
      font-weight: 600; 
    }
    p{
      @media (max-width: 768px) {
        font-size: 16px;
        line-height: 26px;
        font-weight: 400;
        
      }
      font-size: 16px;
      font-weight: 400;
      color: $charcoal;
    }
  }

  #about-us-from-idea{
    background-color: $new-yellow;
    margin-bottom: 60px;
    padding-bottom: 100px;

    @media (max-width: 767px) {
      padding-bottom: 60px;
      margin-bottom: 20px;
    }

    .TextBlock__container {
      @media (min-width: 1200px) {
        width: 1200px;
        padding: 0;
      }
      @media (max-width: 768px) {
        padding: 30px 16px 0;
      }

      .TextBlock__htmlContentContainer{
        div{
          @media (max-width: 768px) {
            margin-bottom: 40px;
          }
        }
      }
    }

    div.TextBlock__container.TextBlock__buttonWrapper{
      display: flex;
      justify-content: flex-end;
      padding: 0 16px !important;

      @media (max-width: 767px) {
        justify-content: center;
        text-align: center;

        a{
          width: 100%;
        }
      }
    }
      
    button{
        align-self: flex-end;
    }

    h4{
      font-size: 32px;
      line-height: 46px;
      font-weight: 400;
      color: $charcoal;

      @media (max-width: 768px) {
        font-size: 24px;
        line-height: 35px;
        font-weight: 400;
        color: $charcoal;
      }
    }  
  }

  #the-mycs-journey {

    .TextBlock__container {
      @media (min-width: 1200px) {
        width: 1200px;
        padding: 30px 0 10px;
      }

      @media (max-width: 768px) {
        padding: 10px 16px 0;
      }
      .TextBlock__htmlContentContainer.TextBlock__twoColumnsContainer{
        margin-bottom: 0;
      }
    }

    div.TextBlock__container{
      div.TextBlock__htmlContentContainer.TextBlock__twoColumnsContainer{
        div{
          div{
            display: flex !important;
            margin-right: 0 !important;
            
            picture{
              width: 100%;
              height: auto;
              img{
                display: flex !important;
                height: 660px !important;

                @media (max-width: 992px) {
                    height: auto !important;
                    width: 100%;
                }
              }                 
            }
          }
        }
      }
    }

    p{
      font-size: 16px;
      line-height: 26px;
      font-weight: 400;
      margin-bottom: 20px;
      color: $charcoal;
    }
  }

  #furniture-for-everyone{
    .TextBlock__container {
      @media (min-width: 1200px) {
        width: 1200px;
        padding: 30px 0;
      }

      @media (max-width: 768px) {
        height: auto !important;
        padding: 30px 16px 0;
      }
    }

    h6{
      font-size: 24px;
      font-weight: 600;
      line-height: 34px;
      margin-bottom: 20px;
    }

    h3{
      font-size: 32px;
      line-height: 45px;
      padding-top: 90px;
      font-weight: 600;
      color: $charcoal;
        @media (max-width: 768px) {
          padding-top: 0;
          font-size: 24px;
          line-height: 34px;
        }
        
    }

    h5{
      font-size: 24px;
      font-weight: 600;
      line-height: 34px;
      margin-bottom: 54px;
      color: $charcoal;
      @media (max-width: 768px) {
        margin-bottom: 32px;
      }
    }

    h6{
      font-size: 16px;
      font-weight: 600;
      line-height: 26px;
      margin-bottom: 30px;
      color: $charcoal;
    }

    p{
      font-size: 16px;
      font-weight: 400;
      line-height: 26px;
      color: $charcoal;
    }

    div.TextBlock__container{
      div.TextBlock__htmlContentContainer.TextBlock__twoColumnsContainer{
        justify-content: space-between;
        column-gap: 16rem;


        @media (max-width: 1024px) {
          column-gap: 5rem;
          align-items: center;
        }

        @media (max-width: 768px) {
          column-gap: 2rem;
        }
        >div{
          div{
            max-width: none;
            display: flex !important;
            margin-right: 0 !important;
            picture{
              width: 100%;
              height: auto;
              img{
                display: flex !important;
                height: auto;
                width: 100%;

                @media (max-width: 992px) {
                    height: auto !important;
                    width: 100%;
                }
              }                 
            }
          }
          &:first-of-type {
            @media (min-width: 768px) {
              max-width: 424px;
            }
          }
        }
      }

    p{
      font-size: 16px;
      line-height: 26px;
      font-weight: 400;
    }
  }
}

  #test-hero{
    background-color: $new-yellow;

    div.TextBlock__container{
      width: 1200px !important;
      padding-bottom: 0;
      padding-top: 80px;

      div.TextBlock__htmlContentContainer{
        div{
          div{
            display: flex !important;
            picture{

              @media (max-width: 1200px) {
                height: auto !important;
                width: auto !important;
              }
              width: 100%;
              height: auto;
              img{
                display: flex !important;
                height: auto;

                @media (max-width: 992px) {
                    height: auto !important;
                    width: 100%;
                }
              }                 
            }
          }
        }
      }
    }
  }
    

  div.HeaderImage__banner{
    div.container{
      div.HeaderImage__linksLayer{
        div.HeaderImage__overlay.HeaderImage__lightMode{
          picture{
            .HeaderImage__image[srcset*="24DEhqmoPfaooeKsx3VNcX"]{
              height: 680px;
              max-height: 680px;
              @media (max-width: 768px) {
                height: 512px;
              }
            }
          }
        }
      }
    }

    div.HeaderImage__textContainer{
      margin-left: auto;
      margin-right: auto;

      @media (min-width: 768px) {
        max-width: 738px;
      }

      @media (min-width: 992px) {
        max-width: 962px;
      }

      @media (min-width: 1200px) {
        max-width: 1200px;
      }

      div.HeaderImage__textLayer{

        @media (max-width: 768px) {
          top: auto;
          bottom: 0;
        }

        max-width: 560px;
        bottom: 35%;
        margin-left: 0;

        div.HeaderImage__subtitle{
          @media (max-width: 768px) {
            font-size: 40px;
            line-height: 54px;
            font-weight: 600;
          }
          font-size: 52px;
          line-height: 70px;
          font-weight: 600;
        }
      }
    }
  }

  .HeaderImage__banner:has(picture > img[srcset*="24DEhqmoPfaooeKsx3VNcX"]) {
    .HeaderImage__overlay.HeaderImage__lightMode{
      background-image: none
    }
  }

  div.ProductFeatures__featuresContainer{
    @media (max-width: 768px) {
      padding-top: 10px;
    }
    div.FurnitureFeatureListGrid__container{
      max-width: 1235px;
      div.FurnitureFeatureListGrid__allFeaturesContainer{
        div.FurnitureFeatureListGrid__tilesContainer{
          div.FurnitureFeatureListGrid__featuresContainer{
            flex-wrap: nowrap;
            @media (max-width: 768px) {
              display: grid;
              grid-template-columns: repeat(1, 1fr);
              grid-column-gap: 10px;
            }
            div.FurnitureFeatureListGrid__tileContainer{
              width: 360px;
              @media (max-width: 768px) {
                width: auto;
              }
              div{
                div.FurnitureFeatureTile__titleContainer{
                  @media (max-width: 768px) {
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    margin: 0;
                  }
                  div.FurnitureFeatureTile__buttonContainer{
                    opacity: unset;
                    @media (max-width: 1024px) {
                      width: auto;
                    }
                    .Button__button.Button__ovalBlackWhiteCta{
                      border: none;
                      padding: 0;
                      height: 36px;
                      .Button__button__text{
                        display: none;
                      }
                      div.Icon__iconContainer.Button__iconContainer{
                        height: 36px;
                        width: 36px;
                        margin-right: 0;

                        .Button__icon.Icon__icon{
                          padding-top: 6px;
                          .Button__icon-svg.Icon__icon-svg{
                            height: 24px;
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
            div.FurnitureFeatureListGrid__tileContainer:nth-of-type(2n + 2) {
              margin-right: 20px;
              @media (max-width: 768px) {
                margin-right: 0;
              }
            }
          }
        }
      }
    }
  }

  .ProductsCarousel__container{
    h3{
      display: none;
    }
    .Carousel__slider{
      @media (min-width: 1200px) {
        max-width: 1200px;
        margin-left: auto;
        margin-right: auto;
        padding: 0;
      }

      @media (max-width: 1199px) {
        padding: 0 16px;
      }

      .slick-slider.ProductsCarousel__slider.slick-initialized{
        button{
          display: none !important;
        }
        .slick-list{
          .slick-track{
            .slick-slide{
              .ProductsCarousel__mediaPreview{
                .MediaPreview__container{
                  .MediaPreview__imageBlock{
                    picture{
                      img{
                        @media (min-width: 769px) {
                          min-height: 486px;
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  div.ProductFeatures__featuresContainer 
  { 
    div.FurnitureFeatureListGrid__container {
      div.FurnitureFeatureListGrid__allFeaturesContainer {
        div.FurnitureFeatureListGrid__tilesContainer {
          margin: 0 16px;
          div#features-details-options-anchor {
            div.FurnitureFeatureListGrid__featuresContainer {
              @media (max-width: 768px) {
                display: flex;
                flex-direction: column;
              }
            
              flex-direction: row;
              max-width: 1200px;
              div.FurnitureFeatureListGrid__additionalTileContainer{
                @media (max-width: 768px) {
                  width: 100%;
                }

                div{
                  div.FurnitureFeatureTile__titleContainer{
                    display: none !important;
                  }
                }    
                
                .FurnitureFeatureTile__additionalTile{
                  border-radius: 0;
                  cursor: default;
                  @media screen and (min-width: 768px) {
                    height: 386px;
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  #about-us-rating{
    background-color: $dark-green;
    margin-top: -2px;
    padding-top: 2px;
    overflow: auto;

    .TextBlock__container {
      @media (min-width: 1200px) {
        width: 1200px;
        padding: 10px 0 20px;
      }
      @media (max-width: 768px) {
        padding: 30px 16px 0;
      }
    }

    div.TextBlock__defaultLayout.TextBlock__container{
      padding: 0;
      padding-bottom: 24px;
      margin-bottom: 0 !important;
    }

      div.TextBlock__htmlContentContainer.TextBlock__twoColumnsContainer {
        margin-bottom: 20px;
        align-items: center;

        @media (max-width: 768px) {
          display: flex;
          flex-direction: row;
        }
        
        div{
          max-width: 160px;
          margin-bottom: 0;
        }
      }
    
    p{
      color: $light-blue;
      font-size: 20px;
      font-weight: 400;
      line-height: 30px;
    }

    .TextBlock__container{
      .TextBlock__htmlContentContainer.TextBlock__twoColumnsContainer{
        div{
          div{
            picture{
              img{
                height: 18px !important;
              }
            }
          }
        }
      }
    }
  }

  #about-us-testimonials{
    margin: 0;
    margin-top: -2px;
    overflow: auto;
    .HeaderImageCarousel__container{
      .HeaderImageCarousel__carouselWrapper{
        .HeaderImageCarousel__carousel{
          .Carousel__slider.Carousel__fullWidth{
            .slick-slider.slick-initialized{
              .slick-arrow {
                top: 85%;
                border: 1px solid $white;
                border-radius: 25px;
                height: 36px;
                width: 36px;

                &:before {
                  height: 36px;
                  width: 36px;
                  background-size: 18px;
                  background-position: center;
                  background-repeat: no-repeat;
                  content: "";
                  display: block;
                }

                &:hover:before {
                  border: 2px solid $white;
                  border-radius: 25px;
                }

                @media (max-width: 768px) {
                  top: 90%;
                }
              }
              
              .slick-arrow.slick-prev{
                right: calc(14.64% + 5px)
              }
              .slick-arrow.slick-next{
                right: calc(8.57%);
              }
              
              @media (min-width: 1480px) {
                .slick-arrow.slick-prev{
                  right: calc(205px + ((100vw - 1400px) / 2));
                }
                .slick-arrow.slick-next{
                  right: calc(120px + ((100vw - 1400px) / 2));
                }
              }

              @media (max-width: 768px){
                .slick-arrow.slick-prev{
                  right: calc(25% + 5px)
                }
                .slick-arrow.slick-next{
                  right: calc(1%);
                }
              }

              .slick-prev{
                left: unset;

              }
              .slick-track{
                .slick-slide{
                  div{
                    div{
                      .HeaderImage__banner.HeaderImage__noImage{
                        background: $dark-green;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    .HeaderImage__banner.HeaderImage__carousel.HeaderImage__noImage{
      background: $dark-green;
      
      @media (max-width: 768px) {
        min-height: 650px;
      }
    }

    .Carousel__slider.Carousel__fullWidth{
      .slick-slider.slick-initialized{
        .slick-dots{
          display: none !important;
        }
      }
    }
    .HeaderImage__title{
      font-size: 24px;
      line-height: 34px;
      font-weight: 600;
      color: $white;
      margin: 0 0 17px;
    }

    .HeaderImage__subtitle{
      font-size: 36px;
      font-weight: 400;
      line-height: 48px;
      margin-bottom: 40px;
      color: $white;
      font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;

      @media (max-width: 768px) {
        font-size: 26px;
        line-height: 38px;
        margin-bottom: 60px;
      }

      ins{
        color: $new-yellow;
      }
    }
    
    div.HeaderImage__banner{
      div.HeaderImage__textContainer{
        @media (max-width: 768px) {
          padding: 10px;
        }
        div.HeaderImage__textLayer{
          max-width: 860px;
          margin: 0;
          text-align: left;
          display: flex;
          flex-direction: column-reverse;
          margin-bottom: 80px;

          @media (max-width: 768px) {
            margin-left: 10px;
            max-width: 340px;
            margin-bottom: 30px;
          }
        }
      }
    }
  }

  .HeaderImage__banner:has(picture > img[srcset*="3xpCiSzcIO3JazoBxyEiPh"]) {
    background-color: $new-yellow;
    padding-top: 80px;
    @media (max-width: 768px) {
      padding-top: 40px;

      img{
        padding: 0 16px;
      }
    }
  }

  .ProductFeature__buttonContainer{
    .Button__button.Button__primaryCtaFallback {
      color: $charcoal !important;
    }
  }
}