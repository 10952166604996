@charset "utf-8";

//
// Variables and mixins that are shared between the global CSS and CSS modules
//
@import '../../../../../scss/functions';
@import '../../../../../scss/config';
@import '../../../../../scss/mixins';
@import '../../../../../scss/vars';
@import '../../../../../scss/media_queries';

.wrapper {
  position: relative;
  width: 100vw;
  background: $white;
}

.container {
  display: flex;
  align-content: center;
  align-items: center;
  padding: 3px 10px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.15);
}

.menuContainer {
  width: 65px;
}

.logoContainer {
  width: 96px;
  margin: auto;
}

.logo {
  display: block;
  width: 96px;

  span,
  svg {
    display: block;
    height: 22px;
    width: 96px;
  }
}

.linksContainer {
  display: flex;
  width: 75px;
}

.linkIcon {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 40px;
  min-width: 33px;

  span,
  svg {
    display: block;
  }

  &:last-child {
    margin-left: 6px;
  }
}

.linkIconActive {
  &:after {
    color: $mx-color-primary-dark !important;
  }

  svg {
    g {
      stroke: $mx-color-primary-dark;
    }

    path:first-of-type,
    circle {
      stroke: $mx-color-primary-dark;
      fill: $mx-color-primary-dark;
    }
  }
}

.linkIconCartActive {
  &:after {
    color: $mx-color-primary-dark !important;
  }
}

:global(.dark-mode) .wrapper {
  .container {
    background-color: $charcoal;

    .logo {
      * {
        fill: $white;
      }
    }

    .linkIcon {
      * {
        stroke: $white;
      }
    }

    .menuButtonIcon {
      background-color: $white;
    }
  }

  :global {
    .NavigationMenuOverlay__menuButton:hover {
      background-color: $charcoal;
    }
    .NavigationMenuOverlay__menuButtonText {
      color: $white !important;
    }
    .NavigationMenuOverlay__menuButtonIcon {
      background-color: $white !important;
    }
    .NavigationMenuGroups__bottomContainer {
      background-color: $charcoal;
    }
    .NavigationMenuGroups__phoneButtonContainer {
      background-color: $charcoal;
    }
    .NavigationMenuGroups__activeLink {
      svg {
        *[fill*='#'] {
          fill: $charcoal !important;
        }

        *[stroke*='#']:not([stroke*='#FFF']) {
          stroke: $charcoal !important;
        }

        *[stroke*='#FFF'][fill*='#'] {
          stroke: $white;
        }
      }

      color: $charcoal !important;
    }
  }
}
