/* We will display icons via the i-tag */
i[class^='i-'] {
  @include inline-block;

  /* IE9+ */
  background-size: 100% 100%;
}

@include block(i-red-plus) {
  background: url('/img/icons/general/plus.svg') 0 0 no-repeat;
  background-size: cover;
  height: 100%;
  width: 100%;
}

@include block(i-red-arrow) {
  background: url('/img/icons/general/arrow-left.svg') 0 0 no-repeat;
  background-size: cover;
  height: 100%;
  width: 100%;
}

@include block(mx-arrow) {
  cursor: pointer;
  margin-top: -50px;
  position: absolute;
  top: 50%;
  background-color: $light-peach;
  padding: 5px;
  z-index: 2000;

  @include transition(background 100ms ease-in);
  @include user-select(none);

  &:after {
    background: url('/img/icons/general/arrow-chevron-left.svg') no-repeat
      center;
    background-size: contain;
    content: '';
    display: block;
    height: 47px;
    width: 50px;
    z-index: 2000;

    @include max-screen(992px) {
      height: 36px;
      width: 36px;
    }
  }

  @include min-screen(993px) {
    &:hover {
      background-color: $ash;
      &:after {
        background-image: url('/img/icons/general/arrow-chevron-left-white.svg');
      }
    }
  }

  @include max-screen(992px) {
    &:active {
      background-color: $ash;
      &:after {
        background-image: url('/img/icons/general/arrow-chevron-left-white.svg');
      }
    }
  }

  @include modifier(left) {
    left: -90px;

    @include mobile(all) {
      left: -15px;
    }
  }

  @include modifier(right) {
    right: -90px;

    @include transform(rotate(180deg));

    @include mobile(all) {
      right: -15px;
    }
  }

  @include modifier(fixed-right) {
    position: fixed !important;
    right: 5%;

    @include transform(rotate(180deg));

    @include max-screen(992px) {
      right: 5px;
    }

    @include max-screen(767px) {
      right: 0;
    }
  }

  @include modifier(fixed-left) {
    position: fixed !important;
    left: 5%;

    @include max-screen(992px) {
      left: 5px;
    }

    @include max-screen(767px) {
      left: 0;
    }
  }

  @include modifier(internal-left) {
    left: -30px;
  }

  @include modifier(internal-right) {
    right: -30px;

    @include transform(rotate(180deg));
  }

  @include modifier(disabled) {
    @include opacity(0.3);

    pointer-events: none;
  }
}
