@charset "utf-8";

//
// Variables and mixins that are shared between the global CSS and CSS modules
//
@import '../../../../../scss/functions';
@import '../../../../../scss/config';
@import '../../../../../scss/mixins';
@import '../../../../../scss/vars';
@import '../../../../../scss/media_queries';

.container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  max-width: 1016px;
  margin: auto;

  @include max-screen(1280px) {
    margin: 0 132px;
  }

  @include max-screen(992px) {
    margin: 0 30px;
  }

  @include max-screen(767px) {
    display: block;
    margin: auto;
  }
}

.group {
  padding: 0 30px 65px 0;
  width: 25%;

  @include max-screen(1023px) {
    width: 33.33333333%;
  }

  ul {
    padding: 0;
    margin: 0;
    list-style: none;

    .item {
      margin-bottom: rem(15);
    }
  }
}

.imageGroup {
  @include max-screen(1023px) {
    order: 10;
  }

  .item {
    display: inline-block;
    vertical-align: middle;

    &:not(:last-child) {
      margin-right: 20px;
    }
  }

  img {
    display: block;
    height: 25px;
    width: 25px;
  }
}

.link {
  @include body-font-2;
  display: block;

  &.activeLink {
    font-weight: bold;
  }

  &[href]:not(.activeLink) {

    &:hover,
    &:focus {
      text-decoration: underline;
    }
  }
}

.groupHeading {
  margin-bottom: 22px;

  .link {
    @include button-cta-font;
    text-transform: uppercase;
  }
}

.rating {
  display: flex;
  margin-bottom: 0.6rem;
}

.ratingText {
  margin-right: 8px;
  @include body-font-2;
  color: $charcoal;
  line-height: 3.2rem;

  @include max-screen(992px) {
    @include body-font-5;
    line-height: 3.2rem;
  }
}

// The payments group
.group_W88wbC3eCIkwc6MwqqgGe {
  ul {
    margin-top: -10px;
  }

  img {
    width: auto;
    height: auto;
    min-width: 45px;
    max-height: 45px;
  }

  @include max-screen(767px) {
    display: none;
  }
}

// Delivery options
.group_1E5LwVFXIUAoEyOEyCAIo0 {
  img {
    height: 26px;
    width: auto;
  }
}

// The references group (EFRE etc)
.group_5lri2igAeWkKy8mAEYQwQu {
  position: absolute;
  z-index: 1;
  right: 0;
  bottom: 0;

  @include max-screen(1023px) {
    position: static;
  }

  .groupHeading {
    display: none;
  }

  img {
    height: 80px;
    width: 80px;
  }

  @include max-screen(767px) {
    display: none !important;
  }
}

// Social links
.group_2m1RrnZYuIcmKUgoGWUOKw {
  @include max-screen(767px) {
    position: absolute;
    bottom: 0;
    display: inline-block;
    width: auto !important;

    ul {
      margin-left: -7px;
    }

    img {
      height: 20px;
    }
  }
}

// Trusted Shop icon
.group_6ReSf9RMuxOy7e3ChsMRq0 {
  display: none;

  @include max-screen(767px) {
    display: inline-block;
    width: auto !important;
    float: right;
    padding: 23px 0 0 !important;

    .groupHeading {
      display: none;
    }

    img {
      width: 70px;
      height: 68px;
    }
  }
}

.cookieConsent {
  @include max-screen(767px) {
    .groupHeading {
      div {
        border-bottom: 1px solid $neutral;
        padding: 5px 0;
      }

      a {
        padding: 10px 12px;
      }
    }
  }
}

// The mobile footer
.phoneContainer {
  .headlineLink .link {
    color: $ash;
    letter-spacing: rem(2);
    padding: 13px 24px;
    text-align: left;
    line-height: rem(25);
    font-size: rem(14);
  }

  .group {
    width: 100%;
    padding: 0;

    &:not(:last-of-type) {
      border-bottom: 1px solid $neutral;
    }
  }

  .panel {
    background: transparent !important;
    padding: 0 !important;

    ul li:before {
      display: none !important;
    }

    .item {
      color: $ash;
      padding: 19px 15px;
      margin-bottom: 0;
      border-top: 1px solid $neutral;
    }
  }

  .imageGroup {
    padding: 23px 10px 0;

    .item {
      border-top: none;
    }

    &.group {
      border-bottom: none;

      .groupHeading {
        margin-bottom: 12px;
      }
    }
  }
}