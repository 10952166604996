@charset "utf-8";

//
// Variables and mixins that are shared between the global CSS and CSS modules
//
@import '../../../../../scss/functions';
@import '../../../../../scss/config';
@import '../../../../../scss/mixins';
@import '../../../../../scss/vars';
@import '../../../../../scss/media_queries';

$spacing: 3.5vw;
$underline: inset 0 -1px 0 0 $mx-color-black;

.menu {
  .link {
    span {
      display: inline;
      padding-bottom: 4px;
    }

    .testimonials {
      display: inline;

      .starsWrapper {
        display: inline-flex;
        margin-left: 10px;
        cursor: default;
      }
    }

    &:hover span:not(.testimonials):not(.starsWrapper) {
      box-shadow: $underline;
    }

    &.highlighted:hover span:not(.testimonials):not(.starsWrapper) {
      box-shadow: inset 0 -1px 0 0 $accent-colour-dark-fallback;
    }
  }

  .heading .link__noUrl {
    cursor: default;
  }

  .heading .link__noUrl:hover span {
    box-shadow: none;
  }
}

.menu__hover {
  .link__active span,
  .item__active .link span {
    box-shadow: none;
  }

  .link__active:hover span:not(.testimonials):not(.starsWrapper),
  .item__active .link:hover span:not(.testimonials):not(.starsWrapper) {
    box-shadow: $underline;
  }
}

.menu__expanded {
  .item__active .link span {
    box-shadow: none;
  }

  .item__active .link:hover span:not(.testimonials):not(.starsWrapper) {
    box-shadow: $underline;
  }
}

.items {
  white-space: nowrap;
  text-align: center;
}

.item {
  display: inline-block;
  margin: 0 1.5vw;

  .link {
    user-select: none;
    @include body-font-3;
    color: $charcoal;

    @include max-screen(992px) {
      @include body-font-5;
    }
  }
}

.item__selected {
  position: relative;
}

.item__active {
  .link span:not(.testimonials):not(.starsWrapper) {
    box-shadow: $underline;
  }
}

.item__notablet {
  @media screen and (max-width: 850px) {
    display: none;
  }
}

.submenus {
  background: $mx-color-grey-6;
  left: 0;
  margin-top: -1px;
  position: absolute;
  top: 100%;
  z-index: 17;
  overflow: hidden;
  width: 100%;

  @include screen(992px, 1250px) {
    .container {
      width: 100%;
      padding: 0 2.5vw;
    }
  }

  .container {
    display: flex;
  }

  .submenu {
    align-content: flex-start;
    align-items: flex-start;
    display: flex;
    flex-flow: row;
    overflow: hidden;
    padding: 0;
    position: relative;
    max-height: 0px;
    transition: max-height 300ms, padding 300ms;
    width: 0;

    @include min-screen(992px) {
      &.threeColumn {
        justify-content: space-between;

        .group {
          margin-right: 30px;

          &:last-child {
            margin-right: 0;
          }
        }
      }
    }

    &.submenu__selected {
      max-height: 800px;
      overflow: visible;
      width: 100%;

      &.submenu__flat {
        max-height: 120px;
        margin-left: 0;
      }
    }

    &:not(.submenu__selected) {
      margin-left: 0 !important;
    }

    @media screen and (max-width: 992px) {
      flex-wrap: wrap;
      margin-left: 3vw;

      &.threeColumn {
        .group {
          min-width: 0;
          width: 33.33%;
          margin-right: 0;
        }
      }
    }
  }
}

.menu__expanded .submenus {
  .submenu {
    padding: 50px 0;

    &.submenu__flat {
      padding: 26px 0 28px;
    }
  }
}

.group {
  margin-bottom: 2vw;
  margin-right: 5.5vw;
  text-align: left;

  @include min-screen(1701px) {
    margin-right: 4vw;
  }

  @include max-screen(1024px) {
    margin-right: $spacing;
  }

  @include max-screen(992px) {
    min-width: 300px;
  }

  &:first-child {
    margin-left: 0;
  }

  &:last-child {
    margin-right: 0;
  }
}

.heading {
  margin-bottom: rem(18);

  .link {
    @include button-cta-font;
    text-transform: uppercase;
    color: $charcoal;
  }
}

.columns {
  display: flex;
  flex-flow: row;
}

.column {
  &:not(:last-child) {
    margin-right: 3vw;
  }

  div {
    line-height: 0;
  }

  .link {
    @include body-font-2;
    color: $charcoal;
    margin: 3px 0;
  }
}

.link {
  display: inline-block;
  position: relative;
  user-select: none;

  &.highlighted {
    color: $accent-colour-dark-fallback;
  }
}

.link__active span:not(.testimonials):not(.starsWrapper) {
  box-shadow: $underline;
  cursor: default;
}

.submenu__flat {
  &.submenu__selected {
    display: flex;
    justify-content: center;
  }

  .group {
    margin-bottom: 0;
    margin-right: 0;
  }

  .heading {
    display: none;
  }

  .columns {
    display: block;
  }

  .column {
    margin-right: 0;

    div {
      display: inline-block;
      margin-right: $spacing;
      text-align: center;

      &:last-child {
        margin-right: 0;
      }
    }
  }
}

.submenu__images {
  margin: 0;

  .column {
    text-align: center;

    div {
      margin: 1vw 3vw;
    }
  }
}

.edit {
  position: absolute;
  left: 0;
  top: 28px;
  z-index: 2;
}

.image {
  display: block;
  margin: 0 auto 15px;
  width: 230px;
}

.rating {
  display: flex;
  align-items: center;
}

.ratingText {
  display: inline;
  margin-right: 8px;
  text-wrap: nowrap;
  @include body-font-2;
  color: $charcoal;
  line-height: 3.2rem;

  @include max-screen(992px) {
    @include body-font-5;
    line-height: 3.2rem;
  }
}
