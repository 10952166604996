@charset "utf-8";

//
// Variables and mixins that are shared between the global CSS and CSS modules
//
@import '../../../../../scss/functions';
@import '../../../../../scss/config';
@import '../../../../../scss/mixins';
@import '../../../../../scss/vars';
@import '../../../../../scss/media_queries';

@keyframes slideDown {
  0% {
    transform: translateY(-110%);
  }
  100% {
    transform: translateY(0);
  }
}

.container {
  &.animated.bottom {
    transform: translateY(100%);
    transition: transform 300ms ease-out;
  }

  &.docked {
    position: fixed;
    z-index: 10;

    &.animated:not(.bottom) {
      animation: slideDown 300ms ease-out;
    }

    &.bottom {
      transform: translateY(0%);
    }
  }
}
