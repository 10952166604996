@charset "utf-8";

//
// Variables and mixins that are shared between the global CSS and CSS modules
//
@import '../../../../../scss/functions';
@import '../../../../../scss/config';
@import '../../../../../scss/mixins';
@import '../../../../../scss/vars';
@import '../../../../../scss/media_queries';

.inputContainer {
  position: relative;
}

.input {
  @include body-font-2;
  background-color: transparent;
  border: 1px solid $mx-color-grey-2;
  border-radius: 0 !important;
  box-sizing: border-box;
  color: black;
  height: 40px;
  margin: 24px 0;
  padding: 0 10px;
  width: 100%;

  @include placeholder-color($mx-color-grey-3 !important);

  &:focus:not(:read-only),
  &:active:not(:read-only) {
    border-color: $mx-color-secondary;

    + .label {
      color: $mx-color-secondary;
    }
  }

  &:read-only {
    color: $neutral;
    border-color: $neutral;
  }
}

.textarea {
  height: 72px;
}

.label {
  color: $charcoal;
  left: 0;
  position: absolute;
  top: 0;
  @include body-font-4;
}

.error {
  border: 1px solid $mx-color-red;
}

.errorMessage {
  color: $mx-color-red;
  position: absolute;
  right: 0;
  top: 0;
  @include body-font-4;
}

.errorMessageBottom {
  left: 0;
  top: 50px;
}

.disabled {
  pointer-events: none;
}

.modernLayout {
  .input {
    border-color: $grey-sky;
    transition: border-color 0.2s ease-in-out;
    @include mx-font-body-2;
    font-size: rem(12) !important;
    line-height: rem(20);
    height: 40px;

    &:hover {
      border-color: $ash;
    }

    &:focus,
    &:active {
      border-color: $pale-gold;

      + .label {
        color: inherit;
      }
    }
  }

  .label {
    @include mx-font-body-2;
    font-size: rem(12);
    line-height: rem(20);
    color: $ash;
  }

  .error {
    border-color: $mx-color-red;
    transition: border-color 0.2s ease-in-out;
  }

  .errorMessage {
    top: 4px;
  }
}

.validCheckMessage {
  display: flex;
  flex-direction: column;
}

.validCheckIcon {
  height: 25px;
  width: 25px;

  position: absolute;
  top: 31px;
  right: 9px;

  transition: border-color 0.2s ease-in-out;
}
