.mx-font-1,
.mx-font-h2 {
  @include mx-font-1;
}

.mx-font-2,
.mx-font-h1 {
  @include mx-font-2;
}

.mx-font-3,
.mx-font-subheading {
  @include mx-font-3;
}

.mx-font-4,
.mx-font-title {
  @include mx-font-4;
}

.mx-font-5,
.mx-font-body,
.mx-link,
.mx-link--primary,
.mx-link--secondary {
  @include mx-font-5;
}

.mx-font-6,
.mx-font-body-2 {
  @include mx-font-6;
}

.mx-font-7,
.mx-font-quote-caption {
  @include mx-font-7;
}

.mx-link {
  @include mx-link;
}

.mx-link--primary {
  @include mx-link--primary;
}

.mx-link--secondary {
  @include mx-link--secondary;
}

.mx-number--small,
.mx-number--big {
  @include mx-number--big;
}

.mx-number--small {
  @include mx-number--small;
}

.mx-font-quote {
  @include mx-font-quote;
}

.mx-font-quote-2 {
  @include mx-font-quote-2;
}

.mx-font-quote-caption-title {
  @include mx-font-quote-caption-title;
}

.mx-font-product-caption {
  @include mx-font-product-caption;
}

.mx-font-caption {
  @include mx-font-caption;
}

.mx-font-h1-alt {
  @include mx-font-h1-alt;
}

.mx-font-h2 {
  @include mx-font-h2;
}
