@charset "utf-8";

//
// Variables and mixins that are shared between the global CSS and CSS modules
//
@import '../../../../../scss/functions';
@import '../../../../../scss/config';
@import '../../../../../scss/mixins';
@import '../../../../../scss/vars';
@import '../../../../../scss/media_queries';

@keyframes slideDown {
  0% {
    max-height: 0;
  }
  100% {
    max-height: 3000px;
  }
}

.content {
  display: none;
}

.open {
  .content {
    display: block;
    animation: slideDown 600ms ease-in;
    overflow: hidden;
  }
}
