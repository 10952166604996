$borderColor: #d6d6d6;

#CybotCookiebotDialog {  
  @include min-screen(600px) {
    max-width: 700px !important;
  }
  
  @include min-screen(1280px) {
    &[data-template="bottom"] {
      min-width: 1240px !important;
      width: auto !important;
      margin: 0 auto;
    }
  }

  // Template specific styles
  &[data-template="popup"] {
    // Hidden decline all button in popup
    #CybotCookiebotDialogBodyButtonDecline {
      display: none;
    }

    #CybotCookiebotDialogBodyEdgeMoreDetails {
      justify-content: flex-start;
    }
  }

  // Content wrapper styles
  .CybotCookiebotDialogContentWrapper {
    flex-direction: column !important;

    #CybotCookiebotDialogTabContent {
      margin-left: 0 !important;
    }
  }

  // Dialog body styles
  #CybotCookiebotDialogBody {
    .CybotCookiebotScrollContainer {
      border-bottom: 0;
      
      @include max-screen(600px) {
        border-bottom: 1px solid $borderColor;
      }
    }
  }

  // Top Nav container
  #CybotCookiebotDialogNavList {
    // Nav links
    .CybotCookiebotDialogNavItemLink {
      @include body-font-2;
      color: $charcoal;
      
      &:hover {
        color: $accent-colour;
      }

      &.active {
        border-bottom: 3px solid $charcoal;
        
        &:hover {
          color: $charcoal;
        }
      }
    }

    // Hidden column about cookies in popup
    #CybotCookiebotDialogNavAbout,
    .CybotCookiebotDialogNavItem:last-child {
      display: none;
    }
  }

  // Typography styles
  #CybotCookiebotDialogBodyContentTitle {
    @include h3-font-demi;
  }

  #CybotCookiebotDialogBodyContentText,
  #CybotCookiebotDialogDetailBodyContentTextAbout,
  .CybotCookiebotDialogDetailBodyContentCookieContainerButton {
    @include body-font-2;
  }

  // Hidden elements
  #CybotCookiebotDialogPoweredByText,
  #CybotCookiebotDialogPoweredbyCybot {
    display: none;
  }

  // Bottom wrapper styles
  .CybotCookiebotDialogBodyBottomWrapper {
    margin: 0 0 20px;

    #CybotCookiebotDialogBodyEdgeMoreDetails {
      #CybotCookiebotDialogBodyEdgeMoreDetailsLink {
        @include button-cta-font;
        letter-spacing: rem(0.25);
        color: $ash;
        border-bottom: 1px solid $charcoal;
        
        // Hidden arrow in more details button
        &::after {
          display: none;
        }
      } 
    }
  }

  // Button wrapper styles
  #CybotCookiebotDialogBodyButtonsWrapper {
    #CybotCookiebotDialogBodyLevelButtonCustomize {
      @include min-screen(1280px) {
        margin-bottom: 0;
      }
      
      .CybotCookiebotDialogArrow {
        display: none;
      }
    }
  }

  // Consent Button styles
  .CybotCookiebotDialogBodyLevelButtonWrapper {
    .CybotCookiebotDialogBodyLevelButtonLabel {
      .CybotCookiebotDialogBodyLevelButtonDescription {
        @include body-font-2;
        color: $charcoal;
        font-weight: normal;
      }
    }
  }

  #CybotCookiebotDialogBodyLevelButtonLevelOptinAllowAll {
    margin-bottom: 0 !important;
  }

  // Button container styles
  #CybotCookiebotDialogBodyLevelButtonsSelectPanel {
    border: none;

    .CybotCookiebotDialogBodyLevelButtonWrapper {
      border: none;
    }
  }

  // Footer styles
  #CybotCookiebotDialogFooter {
    #CybotCookiebotDialogBodyButtons {
      #CybotCookiebotDialogBodyButtonsWrapper {
        gap: 10px;
      }
    }

    @include min-screen(1280px) {
      width: 100% !important;

      #CybotCookiebotDialogBodyButtons {
        flex-direction: row;
        max-width: none;

        #CybotCookiebotDialogBodyButtonsWrapper {
          flex-direction: row;
          justify-content: space-between;
          align-items: center;
          gap: 18px;

          .CybotCookiebotDialogBodyButton {
            width: -webkit-fill-available;
          }
        }
      }
    }
  }
}