@charset "utf-8";

//
// Variables and mixins that are shared between the global CSS and CSS modules
//
@import '../../../../../scss/functions';
@import '../../../../../scss/config';
@import '../../../../../scss/mixins';
@import '../../../../../scss/vars';
@import '../../../../../scss/media_queries';

@mixin ctaFont {
  @include button-cta-font;
  text-transform: uppercase;
}

@mixin cta($color, $hoverColor, $padding: 0) {
  @include ctaFont;
  background-color: $color;
  border-color: $color;
  color: $mx-color-white !important;
  padding: #{8 - $padding}px #{35 - $padding}px;

  &:hover {
    background-color: $hoverColor;
  }
}

@mixin ctaFlat($color, $hoverColor) {
  @include ctaFont;
  background-color: transparent;
  text-decoration: underline;
  text-underline-offset: 4px;
  color: $color;
  padding: 0 0 2px;

  &:hover {
    border-color: $hoverColor;
    color: $hoverColor;
  }
}

@mixin coloredSquare() {
  @extend .bigSquare;
  padding: 5px;
  text-transform: none;

  &:hover,
  &:active,
  &.active,
  &:visited,
  &.selected {
    border-color: transparent;
    color: $mx-color-white;
  }

  svg * {
    stroke: $mx-color-white !important;
  }
}

@mixin bubble($content, $icon-size, $bubble-size, $font-size) {
  @include mx-number--small;
  background: $mx-color-secondary-light;
  border-radius: 50%;
  color: $mx-color-black;
  content: $content;
  font-size: $font-size;
  height: $bubble-size;
  left: calc(#{$icon-size} - 12px);
  letter-spacing: normal;
  line-height: $bubble-size;
  position: absolute;
  text-align: center;
  top: -6px;
  width: $bubble-size;
}

.button {
  background-color: transparent;
  border: none;
  border-radius: 0;
  box-sizing: border-box;
  color: black;
  display: inline-block;
  line-height: normal;
  padding: 0;
  vertical-align: middle;
  user-select: none;
  transition: all 0.1s linear;
  outline: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);

  .button__text {
    overflow: hidden;
    text-align: center;
  }

  &:hover {
    cursor: pointer;
  }

  &:focus {
    outline: none;
  }

  &.centered {
    display: block;
    text-align: center;
    max-width: fit-content;
    margin-left: auto;
    margin-right: auto;
  }

  &.bottomMargin {
    margin-bottom: 30px;
  }

  &.round {
    $height: 40px;
    border-radius: $height * 0.5;
    background-color: $ash;
    color: $mx-color-white;
    font-size: rem(14);
    height: $height;
    line-height: $height - 2px;
    padding: 0 16px;
    margin: 0 4px;
    font-family: $base-font-family;
    letter-spacing: rem(0.6);
    text-transform: capitalize;

    .iconContainer {
      display: inline-block;
      height: 1.7em;
      width: auto;
      vertical-align: middle;
    }

    .icon {
      height: 1.7em;
    }

    .button__text {
      display: inline-block;
      margin-left: 6px;
      vertical-align: middle;
    }

    svg {
      [fill*='#']:not(path) {
        fill: $mx-color-white;
      }

      [stroke*='#']:not(path) {
        stroke: $mx-color-white;
      }

      path[stroke*='#'],
      polyline {
        stroke: $ash;
      }
    }

    &:active,
    &:hover {
      background-color: $charcoal;

      path[stroke*='#'],
      polyline {
        stroke: $charcoal;
      }
    }

    &.active {
      background-color: $mx-color-primary-dark;

      .button__text {
        color: $mx-color-white;
      }

      path[stroke*='#'],
      polyline {
        stroke: $mx-color-primary-dark;
      }
    }
  }

  &.smallSquare,
  &.bigSquare,
  &.bigRectangle {
    background-color: transparent;
    border: 1px solid transparent;
    color: black;
    min-height: 100px;
    width: 100px;
    padding: 5px;
    word-break: break-word;

    .icon {
      height: 48px;
      width: 48px;
    }

    .iconSmall {
      height: 35px;
      width: 35px;
    }

    .button__text {
      text-align: center;
      display: inline-block;
      vertical-align: middle;
      @include mx-font-7;
      font-size: rem(12);
      line-height: rem(16);
      white-space: normal;
    }

    &:hover,
    &:active,
    &.active {
      background-color: $mx-color-secondary-lighter;
      border-color: $mx-color-secondary-lighter;
      color: black;
    }

    &:visited,
    &.selected {
      background-color: transparent;
      border-color: $mx-color-black;
      color: black;
    }
  }

  &.bigSquareCta,
  &.smallSquareCta {
    background-color: transparent;
    border: 1px solid transparent;
    color: $ash;
    min-height: 100px;
    width: 100px;
    padding: 5px;
    word-break: break-word;

    .icon {
      height: 30px;
      width: 48px;

      svg * {
        transition: all 0.1s linear;
      }
    }

    .button__text {
      text-align: center;
      display: inline-block;
      vertical-align: middle;
      @include mx-font-title;
      margin-top: 5px;
      letter-spacing: rem(2);
      line-height: rem(20);
      white-space: normal;
      text-transform: uppercase;
    }

    &:hover,
    &:active,
    &.active,
    &:visited,
    &.selected {
      color: $mx-color-primary;

      svg *[stroke*='#']:not([stroke*='#FFF']) {
        stroke: $mx-color-primary;
      }

      svg *[fill*='#'] {
        fill: $mx-color-primary;
      }
    }
  }

  &.colorButton {
    width: 100% !important;
    min-width: 100px;
  }

  &.smallCircle,
  &.bigCircle,
  &.bigSquareFilled,
  &.bigRectangleFilled {
    background-color: white;
    border: 1px solid white;
    color: black;
    min-height: 100px;
    width: 100px;
    padding: 5px;

    .icon {
      height: 48px;
      width: 48px;
    }

    .iconSmall {
      height: 35px;
      width: 35px;
    }

    .button__text {
      text-align: center;
      display: inline-block;
      vertical-align: middle;
      @include mx-font-7;
      font-size: rem(12);
      line-height: rem(18);
    }

    &:hover,
    &:active,
    &.active {
      background-color: $mx-color-secondary-lighter;
      border-color: $mx-color-secondary-lighter;
      color: black;
    }

    &:visited,
    &.selected {
      background-color: transparent;
      border-color: $mx-color-black;
      color: black;
    }
  }

  &.dropShadow {
    box-shadow: 0 0 5px $mx-color-grey-4;
  }

  &.bigRectangle,
  &.bigRectangleFilled {
    width: auto;
    min-width: 120px;
  }

  &.smallCircle,
  &.bigCircle {
    border-radius: 50%;
    height: 55px;
    min-height: 55px;
    padding: 0;
    width: 55px;

    .button__text {
      display: none;
    }

    .icon {
      height: 55px;
      padding: 5px;
      width: 55px;
    }

    &:hover,
    &:active,
    &.active {
      background-color: $mx-color-secondary-light;
      border-color: $mx-color-secondary-light;
      color: black;
    }
  }

  &.smallCircle {
    border: none;
    height: 22px;
    min-height: 22px;
    width: 22px;

    .icon {
      height: 22px;
      padding: 0;
      width: 22px;
    }
  }

  &.smallSquare {
    border: none;
    height: auto;
    min-height: 0;
    padding: 0;
    width: auto;

    .icon,
    .iconSmall {
      vertical-align: middle;
    }

    .iconSmall {
      height: 25px;
      width: 25px;
    }

    &:hover,
    &:active,
    &.active {
      background-color: transparent;
      border-color: transparent;
      color: $mx-color-primary;

      circle[stroke*='#'],
      path[stroke*='#'] {
        stroke: $mx-color-primary;
      }

      circle[fill*='#'] {
        fill: $mx-color-primary;
      }
    }
  }

  &.smallSquareCta {
    padding: 5px 0;

    .iconContainer {
      height: 28px;
    }

    .icon {
      height: 25px;
    }

    .button__text {
      font-size: rem(12);
      letter-spacing: rem(1);
      line-height: rem(17);
      margin-top: 0;

      @include max-screen(767px) {
        line-height: rem(15);
      }
    }
  }

  &.bigPrimarySquare {
    @include cta($mx-color-primary, $mx-color-primary-dark);
    @include coloredSquare();

    &:active,
    &.active {
      background-color: $mx-color-grey-2;
    }
  }

  &.ovalBlackWhiteCta {
    height: 42px;
    background-color: transparent;
    width: fit-content;
    border: solid 1px $charcoal;
    border-radius: 20px;
    display: flex;
    justify-content: center;
    color: $charcoal;
    padding: 0 10px;
    align-items: center;

    &:active,
    &.active,
    &:hover {
      background-color: $charcoal;
      span {
        color: $light-pink;
      }
      .iconContainer {
        background-color: $charcoal;
        border-color: $light-pink;
        span {
          svg {
            * {
              stroke-width: 8;
              stroke: $light-pink;
            }
          }
        }
      }
    }
    span {
      white-space: nowrap;
      @include body-font-2;
      text-transform: none;
      color: $charcoal;
    }

    .iconContainer {
      margin-right: 10px;
      height: 20px;
      width: 20px;
      background-color: $light-pink;
      border: solid 1px $charcoal;
      border-radius: 18px;
      span {
        display: flex;
        justify-content: center;
        padding-top: 3px;
        svg {
          height: 12px;
          transform: rotate(0.75turn);
          * {
            stroke-width: 8;
            stroke: $charcoal;
          }
        }
      }
    }
  }

  &.bigSecondarySquare {
    @include cta($mx-color-grey-1, $mx-color-black);
    @include coloredSquare();

    &:active,
    &.active {
      background-color: $mx-color-grey-1;
    }
  }

  &.primaryCta {
    @include cta($mx-color-primary, $mx-color-primary-dark);

    &:active,
    &.active {
      background-color: $mx-color-grey-2;
    }

    &.disabled {
      background-color: $mx-color-grey-2;

      &.inline {
        color: $white;
      }
    }
  }

  &.primaryCtaFallback {
    @include cta($mx-color-primary-dark, $mx-color-grey-1);

    &:active,
    &.active {
      background-color: $mx-color-grey-2;
    }

    &.inline {
      color: $mx-color-white;
      border: 1px solid transparent;

      .icon {
        [fill*='#'] {
          fill: $mx-color-white;
        }

        [stroke*='#'] {
          stroke: $mx-color-white;
        }
      }

      &:hover {
        border: 1px solid $ash;
        background-color: $mx-color-white;
        color: $charcoal !important;

        .icon {
          [fill*='#'] {
            fill: $charcoal;
          }

          [stroke*='#'] {
            stroke: $charcoal;
          }
        }
      }
    }
  }

  &.secondaryCta {
    @include cta($mx-color-grey-1, $mx-color-black);
  }

  &.secondaryLightCta {
    @include cta($ash, transparent);
    border: 1px solid $ash;

    &.inline {
      color: $mx-color-white;

      .text {
        margin-left: 8px;
      }
    }

    .icon {
      [fill*='#'] {
        fill: $mx-color-white;
      }

      [stroke*='#'] {
        stroke: $mx-color-white;
      }
    }

    &:hover {
      color: $charcoal;

      .icon {
        [fill*='#'] {
          fill: $charcoal;
        }

        [stroke*='#'] {
          stroke: $charcoal;
        }
      }
    }
  }

  &.secondaryCtaFallback {
    @include cta($mx-color-secondary-light, $mx-color-secondary);
    color: $mx-color-grey-1;
  }

  &.thirdLevelCta {
    $thirdLevelCtaBorder: 1;
    @include cta(transparent, transparent, $thirdLevelCtaBorder);
    border: #{$thirdLevelCtaBorder}px solid $mx-color-grey-2;
    color: $mx-color-grey-2 !important;

    &:hover {
      border-color: $mx-color-grey-1;
      color: $mx-color-grey-1 !important;
    }
  }

  &.thirdLevelCtaFallback {
    $thirdLevelCtaBorder: 1;
    @include cta(transparent, transparent, $thirdLevelCtaBorder);
    border: #{$thirdLevelCtaBorder}px solid $mx-color-grey-1;
    color: $mx-color-grey-1 !important;

    .icon * {
      stroke: $mx-color-grey-1;
      stroke-width: 3px;
    }

    &:hover {
      border-color: $mx-color-grey-2;
      color: $mx-color-grey-2 !important;

      .icon * {
        stroke: $mx-color-grey-2;
      }
    }
  }

  &.thirdLevelCtaFallbackInverse {
    $thirdLevelCtaBorder: 1;
    @include cta(transparent, transparent, $thirdLevelCtaBorder);
    border: #{$thirdLevelCtaBorder}px solid $mx-color-grey-2;
    color: $mx-color-grey-1;

    .icon * {
      stroke: $mx-color-grey-1;
    }

    &.disabled {
      border: 1px solid $mx-color-grey-2;
      background-color: $mx-color-grey-2;
      color: $white;
      opacity: 1;

      .icon * {
        stroke: $white;
        fill: $white;
      }
    }

    &:hover {
      background-color: $mx-color-grey-2;
      border-color: $mx-color-grey-2;
      color: $white;

      .icon * {
        stroke: $white;
      }
    }
  }

  &.thirdLevelCtaInverse {
    $thirdLevelCtaBorder: 1;
    @include cta(transparent, transparent, $thirdLevelCtaBorder);
    border: #{$thirdLevelCtaBorder}px solid $mx-color-white;
    color: $mx-color-white;

    .icon * {
      stroke: $mx-color-white;
      stroke-width: 3px;
    }

    &.inline {
      color: $mx-color-white;
    }

    &:hover {
      border-color: $mx-color-grey-4;
      color: $mx-color-grey-4;

      .icon * {
        stroke: $mx-color-grey-4;
      }
    }
  }

  &.fullWidthMobile {
    @include max-screen(767px) {
      width: calc(100% - 20px);
    }
  }

  &.thirdLevelPrimaryCta,
  &.thirdLevelPrimaryCta.inline {
    $thirdLevelCtaBorder: 1;
    @include cta(transparent, transparent, $thirdLevelCtaBorder);
    border: #{$thirdLevelCtaBorder}px solid $mx-color-primary-dark;
    color: $mx-color-primary-dark !important;

    .icon * {
      stroke: $mx-color-primary-dark;
      stroke-width: 4px;
    }

    .icon * {
      stroke: $mx-color-primary-dark;
      stroke-width: 4px;
    }

    &:hover {
      background-color: $mx-color-primary-dark;
      border-color: $mx-color-primary-dark;
      color: $white !important;

      .icon * {
        stroke: $white;
      }
    }

    &.loading {
      opacity: 1;
    }
  }

  &.flatPrimaryCta {
    @include ctaFlat($mx-color-secondary, $mx-color-grey-1);
  }

  &.flatPrimaryCtaInverse {
    @include ctaFlat($mx-color-white, $mx-color-primary-dark);
  }

  &.flatSecondaryCta {
    @include ctaFlat($mx-color-grey-1, $mx-color-black);
  }

  &.fullWidth {
    width: 100%;
  }

  &.fullHeight {
    height: 100%;
  }

  &.disabled {
    border: none;
    cursor: not-allowed;
    opacity: 0.3;
    pointer-events: none;
  }

  &.loading {
    opacity: 0.9;
    position: relative;
    cursor: not-allowed;
    outline: none;
    pointer-events: none;

    .icon,
    .button__text {
      visibility: hidden;
    }
  }

  &.inline {
    @include mx-font-title;
    color: $mx-color-grey-2;

    .button__text {
      display: inline-block;
      margin-left: 6px;
      vertical-align: middle;
    }

    &.iconAfter {
      .button__text {
        margin-left: auto;
        margin-right: 6px;
      }
    }

    &:hover {
      color: black;
    }
  }

  &.inlineLeft {
    line-height: 2.8rem;
    padding: 0 20px 0 26px !important;

    @include max-screen(767px) {
      padding: 0px 26px !important;
    }

    &.primaryCta {
      .icon * {
        stroke: $mx-color-white;
      }
    }

    .button__text {
      float: left;
      display: inline-block;
      margin: 0;
      vertical-align: middle;
    }

    .iconContainer {
      float: right;
      height: 30px;
      width: 30px;

      svg {
        height: 30px;
        width: 30px;

        * {
          stroke-width: 3;
        }
      }
    }
  }

  &.counter {
    position: relative;
    white-space: nowrap;

    &:after {
      @include bubble(attr(data-value), 30px, 18px, rem(12));
    }

    &:hover {
      background: transparent;
    }
  }

  &.blackWhite {
    @include cta($mx-color-grey-1, $mx-color-black, 0);
  }

  &.iconCta {
    @include cta($mx-color-primary-dark, $charcoal);
    padding: 8px;

    .icon {
      height: 30px;
      width: 30px;
      stroke-width: 3;
      vertical-align: middle;

      * {
        stroke: $mx-color-white;
      }
    }
  }

  &.expandingIconCta {
    @include maisonneue-book;
    font-size: rem(14);
    line-height: rem(25);
    letter-spacing: rem(0.56);
    text-transform: none;
    width: auto;
    background-color: $white;
    border-radius: 21px;
    padding: 6px;
    color: $charcoal;
    box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.1);

    .iconContainer {
      display: inline-block;
      vertical-align: middle;
      width: auto;

      svg {
        vertical-align: middle;
      }
    }

    span {
      padding: 0 6px;
      vertical-align: middle;
    }
  }

  &.splitButtonLeft,
  &.splitButtonRight {
    @include maisonneue-book;
    font-size: rem(14);
    line-height: rem(25);
    letter-spacing: rem(0.56);
    background-color: $white;
    color: $charcoal;
    height: 42px;
    padding: 6px;
    box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.1);

    .iconContainer {
      display: inline-block;
      vertical-align: middle;
      width: auto;

      svg {
        height: 30px;
        width: 30px;
        vertical-align: middle;

        * {
          stroke: $charcoal;
        }
      }
    }

    &:hover {
      color: $white;
      background-color: $petrol-green;

      svg {
        * {
          stroke: $white;
        }

        ellipse {
          fill: $petrol-green;
        }
      }
    }

    &.loading {
      background-color: $petrol-green;
    }
  }

  &.splitButtonLeft {
    border-radius: 20px 0 0 20px;
    border-right: 1px solid $light-ice;

    .iconContainer {
      padding-right: 5px;
    }
  }

  &.splitButtonRight {
    border-radius: 0 20px 20px 0;

    .iconContainer {
      svg {
        * {
          stroke-width: 2;
        }
      }
    }
  }
}

.elementAfterLabelContainer {
  display: inline;
  position: relative;
  left: 5px;
}
