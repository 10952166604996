@charset "utf-8";

//
// Variables and mixins that are shared between the global CSS and CSS modules
//
@import '../../../../../scss/functions';
@import '../../../../../scss/config';
@import '../../../../../scss/mixins';
@import '../../../../../scss/vars';
@import '../../../../../scss/media_queries';

.form {
  margin: 0;

  .input {
    margin: 30px 0;
    @include body-font-4;

    label {
      display: block;
      font-weight: normal;
    }
    input {
      margin: 6px 0 0;
    }
  }

  .button {
    margin-bottom: 25px;
    width: 100%;
    padding: 10px 35px;
  }

  .labelText {
    @include body-font-4;
    color: $ash;

    a {
      color: $charcoal;
      text-decoration: underline;

      &:hover {
        color: $charcoal;
      }
    }
  }

  .informationParagraph {
    @include maisonneue-book;
    font-size: rem(12);
    line-height: rem(20);
    letter-spacing: rem(0.56);
    color: $neutral;
    text-align: left;

    a {
      color: $neutral;
      text-decoration: underline;

      &:hover {
        color: $charcoal;
      }
    }
  }

  .optionalCheckbox {
    margin: 5px 0 25px !important;

    @include max-screen(767px) {
      margin-bottom: 20px !important;
    }
  }
}
