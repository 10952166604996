@charset "utf-8";

//
// Variables and mixins that are shared between the global CSS and CSS modules
//
@import '../../../../../scss/functions';
@import '../../../../../scss/config';
@import '../../../../../scss/mixins';
@import '../../../../../scss/vars';
@import '../../../../../scss/media_queries';

.clarifyingText {
  margin: 10px 0;
  color: $petrol-green;
  display: flex;

  .clarifyingTextIconContainer {
    width: fit-content;
    padding-right: 2px;
  }

  .clarifyingTextIcon {
    width: 20px;
    height: 20px;

    filter: invert(30%) sepia(31%) saturate(2155%) hue-rotate(149deg)
      brightness(98%) contrast(101%) !important;
    stroke-width: 4px !important;
    path {
      stroke-width: 4px !important;
    }
  }
}
