@charset "utf-8";

//
// Variables and mixins that are shared between the global CSS and CSS modules
//
@import '../../../../../scss/functions';
@import '../../../../../scss/config';
@import '../../../../../scss/mixins';
@import '../../../../../scss/vars';
@import '../../../../../scss/media_queries';

.container {
  position: fixed;
  right: 0;
  top: 0;
  z-index: 16;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
}

.item {
  display: flex;
  margin-left: 10px;
  text-align: center;
  font-size: rem(10);
  line-height: 1.5;
  text-align: center;
  text-transform: uppercase;
  font-weight: bolder;
}

.signOut {
  text-decoration: underline;
  padding: 5px;

  &:hover {
    cursor: pointer;
  }
}

.modeText {
  background-color: $mx-color-red;
  color: $mx-color-white;
  padding: 5px;
}

.email {
  text-transform: none;
  padding: 5px 0 5px 5px;
}

.userMenu {
  display: flex;
  background: $white;
  color: $black;
}
