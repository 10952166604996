@charset "utf-8";

//
// Variables and mixins that are shared between the global CSS and CSS modules
//
@import '../../../../../scss/functions';
@import '../../../../../scss/config';
@import '../../../../../scss/mixins';
@import '../../../../../scss/vars';
@import '../../../../../scss/media_queries';

.container {
  position: relative;
  overflow: hidden;
}

.scrollable {
  overflow-x: auto;
  overflow-y: hidden;
  display: flex;
  flex-wrap: nowrap;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: none;

  &::-webkit-scrollbar {
    display: none;
  }
}

.centered {
  .scrollable {
    justify-content: center;
  }
}

@include min-screen(993px) {
  .wrap {
    .scrollable {
      overflow: visible;
      flex-wrap: wrap;
    }
  }
}

.small {
  &.withArrows .scrollable {
    margin: 0 12px;
  }

  .arrow {
    top: 7px;

    svg {
      width: 15px;
      height: 15px;
      filter: none;
    }

    &.left {
      left: -3px;
    }

    &.right {
      right: -3px;
    }
  }
}

.arrow {
  display: none;
  position: absolute;
  z-index: 1;
  top: 20px;

  svg {
    height: 30px;
    width: 30px;
    filter: drop-shadow(0px 1px 2px rgba(0, 0, 0, 0.5));

    path {
      stroke: $ash;
      stroke-width: 6px;
    }
  }

  &.left {
    left: 0;
  }

  &.right {
    right: 0;
  }
}

.withArrows {
  .arrow {
    display: block;
  }
}
