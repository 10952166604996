@charset "utf-8";

//
// Variables and mixins that are shared between the global CSS and CSS modules
//
@import '../../../../../scss/functions';
@import '../../../../../scss/config';
@import '../../../../../scss/mixins';
@import '../../../../../scss/vars';
@import '../../../../../scss/media_queries';

.container {
  cursor: default !important;
  font-weight: normal !important;
  user-select: none;
  width: 100%;
  white-space: nowrap;
  margin-bottom: 0;
}

.checkbox {
  cursor: pointer;
  display: inline-block;
  width: 20px;
  height: 20px;
  position: relative;
  margin: 0 !important;
  vertical-align: middle;
}

.big .checkbox {
  width: 40px;
  height: 40px;
}

.checkbox:before {
  content: '';
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  border: 1px solid $mx-color-grey-1;
  background: $mx-color-white;
}

.checked .checkbox:after {
  content: '';
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  margin: 3px;
  background: $ash;
}

.big.checked .checkbox:after {
  margin: 5px;
}

.label {
  @include mx-font-caption;
  font-size: rem(14);
  display: inline-block;
  cursor: pointer;
  margin-left: -20px;
  padding-left: 40px;
  vertical-align: middle;
  white-space: normal;
  width: 100%;
}

.big .label {
  margin-left: -40px;
  padding-left: 60px;
}

.checkbox[disabled] {
  pointer-events: none;
  cursor: not-allowed;
}

.disabled .checkbox:before {
  border-color: $mx-color-grey-3;
  cursor: not-allowed;
}

.checked.disabled .checkbox:after {
  opacity: 0.4;
  cursor: not-allowed;
}

.disabled .checkbox + .label {
  color: $mx-color-grey-3;
  cursor: not-allowed;
}

.checkboxInput {
  opacity: 0;
}

.modernLayout {
  .checkbox {
    width: 20px;
    height: 20px;

    &:before {
      border-color: $ash;
    }
  }

  &.checked .checkbox:after {
    margin: 2px;
  }

  .label {
    @include body-font-2;
    margin-left: -28px;
    padding-left: 36px;
  }
}

.leftAlignedLayout {
  .checkbox {
    display: block;
    float: left;
    width: 20px;
    height: 20px;
    margin-right: 10px !important;
  }

  .label {
    @include maisonneue-book;
    font-size: rem(12);
    line-height: rem(20);
    letter-spacing: rem(0.56);
    padding: 0;
    display: block;
    text-align: left;
    margin: 0;
    color: $ash;
  }
}

.errorHighlight {
  :before {
    background-color: $red;
    opacity: 0.5;
  }
}
