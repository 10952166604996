@charset "utf-8";

//
// Variables and mixins that are shared between the global CSS and CSS modules
//
@import '../../../../../scss/functions';
@import '../../../../../scss/config';
@import '../../../../../scss/mixins';
@import '../../../../../scss/vars';
@import '../../../../../scss/media_queries';

.popupOverlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: $black-transparent;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}

.popupContainer {
  position: relative;
  width: 90%;
  max-width: 936px;
  background-color: transparent;
}

.imageWrapper img {
  width: 100%;
  height: auto;
}

.closeButton {
  position: absolute;
  width: 40px;
  top: 10px;
  right: 10px;
  background: transparent;
  border: none;
  cursor: pointer;

  &.blackButton {
    svg path {
      stroke: $black;
    }
  }

  &.whiteButton {
    svg path {
      stroke: $white;
    }
  }
}
