@charset "utf-8";

//
// Variables and mixins that are shared between the global CSS and CSS modules
//
@import '../../../../../scss/functions';
@import '../../../../../scss/config';
@import '../../../../../scss/mixins';
@import '../../../../../scss/vars';
@import '../../../../../scss/media_queries';

.iconContainer {
  user-select: none;
  width: 100%;

  img {
    vertical-align: baseline;
  }
}

.iconContainerInline {
  display: inline-block;
  height: 1.7em;
  width: auto;
  vertical-align: middle;

  .icon {
    height: 1.7em;
  }
}

.icon {
  display: inline-block;

  svg,
  img {
    display: block;
  }
}
