@charset "utf-8";

//
// Variables and mixins that are shared between the global CSS and CSS modules
//
@import '../../../../../scss/functions';
@import '../../../../../scss/config';
@import '../../../../../scss/mixins';
@import '../../../../../scss/vars';
@import '../../../../../scss/media_queries';

.loader {
  background: $mx-color-white;
  min-height: 80vh;

  @include max-screen(767px) {
    font-size: 8px;
  }
}
