@charset "utf-8";

//
// Variables and mixins that are shared between the global CSS and CSS modules
//
@import '../../../../../scss/functions';
@import '../../../../../scss/config';
@import '../../../../../scss/mixins';
@import '../../../../../scss/vars';
@import '../../../../../scss/media_queries';

.container {
  display: block;
  color: $ash;
}

.topContainer {
  padding: 0 5px 0 10px;
}

.headerContainer {
  height: 40px;
  padding: 5px 5px 5px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;

  &:after {
    content: '';
    border-top: 1px solid $neutral;
    width: calc(100% - 15px);
    height: 1px;
    position: absolute;
    left: 5px;
    bottom: 0;
  }

  @include max-screen(320px) {
    padding: 2px 5px 2px 0;
  }
}

.headerText {
  @include button-cta-font;
  color: $charcoal;
  padding-left: 5px;
  text-transform: uppercase;

  @include max-screen(320px) {
    font-size: rem(12);
  }
}

.headerTextIcon {
  @include button-cta-font;
  text-transform: uppercase;
  color: $charcoal;
  display: flex;
  align-items: center;

  &:hover {
    background-color: $light-ice;
  }

  * {
    transition: color 0.1s, stroke 0.1s, fill 0.1s;
  }

  @include max-screen(320px) {
    font-size: rem(12);
  }
}

.headerBackIcon {
  display: inline-block;
  vertical-align: middle;
  line-height: rem(16);
  margin-right: 5px;
  height: 29px;
  width: 29px;

  svg {
    height: 29px;
    width: 29px;
  }

  svg *[stroke] {
    stroke: $charcoal;
    stroke-width: 4;
  }

  svg *:not([stroke]) {
    fill: $charcoal;
  }
}

.headerCloseIcon {
  height: 25px;
  width: 25px;
  display: flex;

  &:hover {
    background-color: $light-ice;
  }

  svg {
    height: 25px;
    width: 25px;

    * {
      stroke: $charcoal;
      stroke-width: 3;
    }
  }
}

.link {
  @include mx-font-body;
  color: $ash;
  display: block;
  padding: 5px 0;

  @include max-screen(320px) {
    font-size: rem(12);
    padding: 2px 0;
  }
}

.productTitle {
  @include body-font;
  color: $charcoal;
  padding: 11px 5px;

  @include max-screen(320px) {
    font-size: rem(14);
    padding: 5px;
  }
}

.productTitleLink {
  padding: 5px 5px 5px 0;

  .link {
    @include body-font;
    color: $charcoal;
    padding: 6px 5px;

    &:hover {
      background-color: $light-ice;
      color: inherit;
    }
  }

  .activeLink {
    background-color: $light-peach;
  }

  @include max-screen(320px) {
    .link {
      font-size: rem(14);
      padding: 5px;
    }
  }
}

.productLinksContainer {
  padding: 0 5px 10px 0;
}

.productLink {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 7px 5px 5.5px;
  position: relative;

  &:before {
    content: '';
    border-top: 1px solid $light-ice;
    width: calc(100% - 10px);
    margin: auto;
    height: 1px;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    z-index: 0;
  }

  &:not(:last-of-type):after {
    content: '';
    border-top: 1px solid $light-ice;
    width: calc(100% - 10px);
    margin: auto;
    height: 1px;
    position: absolute;
    left: 0;
    right: 0;
    bottom: -1px;
    z-index: 1;
  }

  &:hover {
    background-color: $light-ice;
  }

  &.activeProductLink {
    background-color: $light-peach;

    &:before {
      border-color: $light-peach;
      z-index: 2;
    }

    &:after {
      border-color: $light-peach;
    }
  }

  @include max-screen(320px) {
    padding: 3px 5px 2px;
  }
}

.productText {
  @include button-cta-font;
  text-transform: uppercase;
  color: $charcoal;

  @include max-screen(320px) {
    font-size: rem(12);
  }
}

.productArrowIcon {
  height: 22px;
  width: 22px;
  display: flex;
  color: $charcoal;

  svg {
    height: 22px;
    width: 22px;

    * {
      stroke: $ash;
      stroke-width: 5;
    }
  }
}

.productSubLinksContainer {
  padding: 5px 5px 10px 0;
  position: relative;

  &:before {
    content: '';
    border-top: 1px solid $light-ice;
    width: calc(100% - 15px);
    height: 1px;
    position: absolute;
    left: 5px;
    top: 0;
  }

  @include max-screen(320px) {
    padding: 2px 5px 5px 0;
  }
}

.productSubLinkContainer {
  .link {
    padding: 6px 5px 4px;
    @include body-font-2;
    color: $charcoal;

    &:hover {
      background-color: $light-ice;
      color: inherit;
    }
  }

  .activeLink {
    background-color: $light-peach;
  }
}

.bottomContainer {
  background-color: $accent-colour-dark-fallback;
  color: $white;
  min-height: 667px;
}

.customizeContainer {
  padding: 10px 0 12px 15px;

  @include max-screen(320px) {
    padding: 5px 0 6px 15px;
  }
}

.customizeTitle {
  @include body-font;
  color: $white;
  padding: 11px 0;

  @include max-screen(320px) {
    font-size: rem(14);
    padding: 5px 0;
  }
}

.customizeLinkContainer {
  &:last-child {
    .link {
      margin-right: 15px;
    }
  }

  &:hover {
    .link {
      background-color: $ash;
    }

    .linkIcon {
      *[stroke*='#FFF'][fill*='#'] {
        stroke: $ash;
      }
    }
  }

  .link {
    height: 80px;
    color: $white;
    @include body-font-6;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    padding: 5px;
    margin-right: 5px;
    min-width: 58px;

    &.activeLink {
      background-color: $light-peach;
      color: $accent-colour-dark-fallback;

      .linkIcon {
        *[fill*='#'] {
          fill: $accent-colour-dark-fallback;
        }

        *[stroke*='#']:not([stroke*='#FFF']) {
          stroke: $accent-colour-dark-fallback;
        }

        *[stroke*='#FFF'][fill*='#'] {
          stroke: $white;
        }
      }
    }

    @include max-screen(320px) {
      height: 45px;

      .linkText {
        display: none;
      }
    }
  }

  .linkIconContainer {
    height: 44px;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    margin-bottom: 5px;

    @include max-screen(320px) {
      height: 34px;
      margin-bottom: 0;
    }
  }

  .linkIcon {
    *[fill*='#'] {
      fill: $white;
    }

    *[stroke*='#']:not([stroke*='#FFF']) {
      stroke: $white;
    }

    *[stroke*='#FFF'][fill*='#'] {
      stroke: $accent-colour-dark-fallback;
    }

    @include max-screen(320px) {
      height: 26px;
      width: 36px;
    }
  }
}

.footerLinksContainer {
  position: relative;
  padding: 10px;

  &:before {
    content: '';
    border-top: 1px solid $white;
    width: calc(100% - 30px);
    margin: auto;
    height: 1px;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
  }

  @include max-screen(320px) {
    padding: 5px 10px;
  }
}

.footerLinkContainer {
  &:hover {
    .link {
      background-color: $ash;
    }
  }

  .link {
    color: $white;
    @include body-font;
    padding: 3px 5px;

    @include max-screen(320px) {
      font-size: rem(14);
      padding: 1px 5px;
    }
  }
}

.phoneButtonContainer {
  background-color: $accent-colour-dark-fallback;
  padding: 0 10px 20px;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 10%;

  button {
    padding: 7px !important;

    &:hover {
      border-color: $charcoal !important;
      background: $charcoal !important;
      color: $white !important;

      svg * {
        stroke: $white !important;
      }
    }
  }

  @include max-screen(320px) {
    padding: 0 10px 10px;

    button {
      font-size: rem(12) !important;
      padding: 5px !important;
    }
  }

  @include mobile(landscape) {
    position: relative;
    margin-top: 20px;
  }
}
