@charset "utf-8";

//
// Variables and mixins that are shared between the global CSS and CSS modules
//
@import '../../../../../scss/functions';
@import '../../../../../scss/config';
@import '../../../../../scss/mixins';
@import '../../../../../scss/vars';
@import '../../../../../scss/media_queries';

.container {
  background: $mx-color-white;
  position: absolute;
  z-index: 1;
  max-width: 545px;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.header {
  background: $accent-colour;
  padding: 25px 85px;
  text-align: center;
  position: relative;

  h4 {
    @include mx-font-title;
    color: $mx-color-white;
    margin: 0;
  }

  .closeButton {
    position: absolute;
    z-index: 2;
    top: 50%;
    transform: translateY(-50%);
    right: 20px;
    width: 40px;
    height: 40px;

    svg {
      display: block;
      width: 40px;
      height: 40px;

      path {
        stroke: $mx-color-white;
        stroke-width: 3;
      }
    }
  }
}

.content {
  background: $mx-color-white;
  padding: 30px 50px 0;
  text-align: center;

  [class^='SaveDesignForm__header'] {
    display: none;
  }

  p {
    @include mx-font-body-2;
    margin: 0;
  }

  .form {
    padding: 10px 20px 20px;

    label {
      display: block;
      text-align: left;
      margin-bottom: 0;
      font-weight: normal;
      @include mx-font-caption;

      input {
        margin-top: 7px;
      }
    }

    .button {
      width: 60%;
      margin: 0 auto;
    }
  }
}
