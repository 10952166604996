@charset "utf-8";

//
// Variables and mixins that are shared between the global CSS and CSS modules
//
@import '../../../../../scss/functions';
@import '../../../../../scss/config';
@import '../../../../../scss/mixins';
@import '../../../../../scss/vars';
@import '../../../../../scss/media_queries';

.alert {
  @include body-font-2;
  background: $mx-color-secondary-lighter;
  color: $charcoal;
  padding: 10px 15px;
  text-align: center;
  width: 100%;
  position: relative;

  &.infoMessage {
    background: $light-pink;
  }

  &.leftAligned {
    text-align: left;
  }

  @include max-screen(500px) {
    text-align: left;
    padding: 20px;
    display: flex;
    flex-wrap: nowrap;

    &:not(.closeable) .text {
      display: inline-block;
    }

    &.infoMessage {
      display: block;
      padding: 10px 15px;
    }
  }

  &.closeable {
    text-align: center;
    font-size: rem(16);
    letter-spacing: rem(0.6);
    padding-right: 22px;
    opacity: 0;
    transition: opacity 0.4s ease-in-out;

    @include max-screen(992px) {
      padding-right: 30px;
    }

    &:hover {
      cursor: pointer;
    }

    .icon {
      max-height: 20px;
    }

    .iconContainer {
      width: auto;
    }
  }

  &.closed {
    display: none !important;
  }

  &.closing {
    pointer-events: none;
    opacity: 0;
  }

  &.opened {
    opacity: 1;
  }

  .icon {
    height: 33px;
    width: 33px;
    vertical-align: middle;
  }

  .iconContainer {
    display: inline-block;
    width: 33px;
    margin: 0 0 0 0.2em;
  }

  .iconCloseContainer {
    width: 20px;
    height: 20px;
    position: absolute;
    right: 3px;
    top: 3px;

    @include max-screen(992px) {
      width: 25px;
      height: 25px;
    }

    @include max-screen(767px) {
      width: 30px;
      height: 30px;
    }
  }

  .iconClose {
    width: 20px;
    height: 20px;

    svg {
      height: 20px;
    }

    path {
      stroke: $ash;
    }

    @include max-screen(992px) {
      width: 25px;
      height: 25px;
    }

    @include max-screen(767px) {
      width: 30px;
      height: 30px;
    }
  }

  .textContainer {
    display: inline-block;
  }

  .buttonContainer {
    display: inline-block;

    .button {
      font-size: rem(12);
      margin-left: 10px;
    }

    @include max-screen(767px) {
      text-align: center;
      margin-top: 6px;
      display: block;

      .button {
        margin-left: 0;
      }
    }
  }
}

.shareDesign {
  padding: 14px 16px;
  width: fit-content;
  max-width: 90%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: $white;
  margin-bottom: 16px;
}
