@charset "utf-8";

//
// Variables and mixins that are shared between the global CSS and CSS modules
//
@import '../../../../../scss/functions';
@import '../../../../../scss/config';
@import '../../../../../scss/mixins';
@import '../../../../../scss/vars';
@import '../../../../../scss/media_queries';

$desktopImgUrl: 'mycs-img/error/desktop.jpeg';
$mobileImgUrl: 'mycs-img/error/mobile.jpeg';

@mixin backgroundImage($backgroundImageUrl) {
  background: url(#{$backgroundImageUrl});
  background-size: cover;
  background-repeat: no-repeat;
}

.container {
  display: flex;
  flex-direction: column;
  height: 100%;
  @include backgroundImage($desktopImgUrl);
  @include max-screen(1024px) {
    @include backgroundImage($mobileImgUrl);
  }

  @include max-screen(767px) {
    background-image: none;
  }

  .mobileBackgroundContainer {
    display: none;

    @include max-screen(767px) {
      @include backgroundImage($mobileImgUrl);
      display: block;
      padding-bottom: 70%;
    }
  }

  .contentContainer {
    @include max-screen(4096px) {
      padding: 410px 440px;
    }
    @include max-screen(1920px) {
      padding: 150px 210px;
    }
    @include max-screen(1280px) {
      padding: 77px 74px;
      height: 100vh;
    }
    @include max-screen(1024px) {
      padding: 77px 29px;
    }
    @include max-screen(768px) {
      padding: 77px 31px;
    }
    @include max-screen(767px) {
      padding: 25px 20px;
      background-color: $paper;
      height: 100%;
    }

    .iconContainer {
      margin-bottom: 30px;
      @include max-screen(767px) {
        margin-bottom: 10px;
        text-align: center;
      }
    }

    .logo {
      height: 32px;
      width: 96px;
    }

    .textContainer {
      max-width: 539px;
      h1 {
        @include maisonneue-demi;
        font-size: rem(32);
        line-height: rem(52);
        letter-spacing: rem(0.56);
        color: $charcoal;
        margin-bottom: 35px;
        @include max-screen(1280px) {
          margin-bottom: 35px;
          @include h2-font-demi;
        }
        @include max-screen(767px) {
          margin-bottom: 14px;
          @include subheader-font-demi;
        }
      }

      p {
        @include body-font;
        color: $charcoal;
      }
    }
  }
}
