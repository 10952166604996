@charset "utf-8";

//
// Variables and mixins that are shared between the global CSS and CSS modules
//
@import '../../../../../scss/functions';
@import '../../../../../scss/config';
@import '../../../../../scss/mixins';
@import '../../../../../scss/vars';
@import '../../../../../scss/media_queries';

@keyframes load8 {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.loader {
  animation: load8 1.1s infinite linear;
  border: 1.1em solid $mx-color-grey-transparent-3;
  border-left: 1.1em solid $mx-color-primary-light;
  border-radius: 50%;
  height: 10em;
  left: 0;
  margin: auto;
  position: absolute;
  pointer-events: none;
  right: 0;
  top: 40%;
  width: 10em;
  z-index: 16;

  &:after {
    border-radius: 50%;
    height: 10em;
    width: 10em;
  }

  &.small {
    height: 28px;
    width: 28px;
    border-width: 6px;
    border-left: 6px solid $white;
    top: 0;
    bottom: 0;
  }

  &.smallInverse {
    border-left: 6px solid $petrol-green;
  }
}

/*
* Dots
*/
.dots {
  text-align: center;
}

.dots > div {
  width: 18px;
  height: 18px;
  background-color: #333;

  border-radius: 100%;
  display: inline-block;
  animation: skBouncedelay 1.4s infinite ease-in-out both;
}

.bounce1 {
  animation-delay: -0.32s;
}

.bounce2 {
  animation-delay: -0.16s;
}

@keyframes skBouncedelay {
  0%,
  80%,
  100% {
    transform: scale(0);
  }
  40% {
    transform: scale(1);
  }
}
