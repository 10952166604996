@charset "utf-8";

//
// Variables and mixins that are shared between the global CSS and CSS modules
//
@import '../../../../../scss/functions';
@import '../../../../../scss/config';
@import '../../../../../scss/mixins';
@import '../../../../../scss/vars';
@import '../../../../../scss/media_queries';

.dropdownWrapper {
  position: relative;
  margin-left: 5px;
  :global(.Popover-tipShape) {
    fill: $charcoal;
  }
}

.currentLanguage {
  span {
    @include mx-font-body;
    color: $mx-color-black;
    font-weight: normal;
  }

  .icon {
    width: 10px;
    height: 10px;
  }
}

.dropdown {
  position: absolute;
  box-shadow: 0 0 20px $grey-sky;
  background: $charcoal;
  right: -32px;
  opacity: 0;
  z-index: 17;
  margin-top: 10px;
  transition: opacity 300ms;
  width: 130px;

  &:after {
    content: '';
    border: 8px transparent solid;
    border-bottom-color: $charcoal;
    position: absolute;
    top: -16px;
    left: 50%;
    margin-left: -8px; // account for width of arrow
  }

  &.isOpen {
    opacity: 1;
  }
}

.dropdownOption {
  @include mx-font-body-2;
  color: $mx-color-white;
  display: block;
  padding: 11px 18px;
  text-align: left;
  text-transform: capitalize;

  &:hover {
    color: $mx-color-white;
  }
}
