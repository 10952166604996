@font-face {
  font-family: 'MaisonNeue-Demi';
  src: url('/fonts/MaisonNeue-Demi.eot');
  src: url('/fonts/MaisonNeue-Demi.woff2') format('woff2'),
    url('/fonts/MaisonNeue-Demi.woff') format('woff'),
    url('/fonts/MaisonNeue-Demi.ttf') format('truetype'),
    url('/fonts/MaisonNeue-Demi.svg#MaisonNeue-Demi') format('svg'),
    url('/fonts/MaisonNeue-Demi.eot?#iefix') format('embedded-opentype');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'MaisonNeue-Book';
  src: url('/fonts/MaisonNeue-Book.eot');
  src: url('/fonts/MaisonNeue-Book.woff2') format('woff2'),
    url('/fonts/MaisonNeue-Book.woff') format('woff'),
    url('/fonts/MaisonNeue-Book.ttf') format('truetype'),
    url('/fonts/MaisonNeue-Book.svg#MaisonNeue-Book') format('svg'),
    url('/fonts/MaisonNeue-Book.eot?#iefix') format('embedded-opentype');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'MaisonNeue-Mono';
  src: url('/fonts/MaisonNeue-Mono.eot');
  src: url('/fonts/MaisonNeue-Mono.woff2') format('woff2'),
    url('/fonts/MaisonNeue-Mono.woff') format('woff'),
    url('/fonts/MaisonNeue-Mono.ttf') format('truetype'),
    url('/fonts/MaisonNeue-Mono.svg#MaisonNeue-Mono') format('svg'),
    url('/fonts/MaisonNeue-Mono.eot?#iefix') format('embedded-opentype');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
