// Remove links from print media
@media print {
  a[href]:after {
    content: none;
  }
}

// https://gist.github.com/donnierayjones/6fd9802d992b2d8d6cfd
// Render Bootstrap as "small" layout when printing
@media print {
  .col-sm-6 {
    float: left;
  }
  .col-sm-6 {
    width: 50%;
  }
}
