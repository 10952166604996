@charset "utf-8";

//
// Variables and mixins that are shared between the global CSS and CSS modules
//
@import '../../../../../scss/functions';
@import '../../../../../scss/config';
@import '../../../../../scss/mixins';
@import '../../../../../scss/vars';
@import '../../../../../scss/media_queries';

.container {
  display: block;

  &.hidden {
    @include max-screen(767px) {
      display: none;
    }
  }

  @include mobile {
    border-bottom: 1px solid $ice;
  }
}
.dockedContainer {
  @include mobile {
    z-index: 4 !important;
  }
}
