@charset "utf-8";

//
// Variables and mixins that are shared between the global CSS and CSS modules
//
@import '../../../../../scss/functions';
@import '../../../../../scss/config';
@import '../../../../../scss/mixins';
@import '../../../../../scss/vars';
@import '../../../../../scss/media_queries';

.wrapper {
  position: relative;
  width: 100%;
}

.desktopContainer {
  align-content: center;
  align-items: center;
  display: flex;
  flex-flow: row;
  padding: 22px 0;
  text-align: center;
  margin: 0 auto;
  max-width: 1220px;

  @include max-screen(1280px) {
    margin: 0 30px;
  }
}

.logo {
  display: block;
  width: 96px;

  span,
  svg {
    display: block;
    height: 32px;
    width: 96px;
  }
}

.left {
  width: 96px;
}

.menu {
  flex: 2;
  padding: 0;
  text-align: center;
}

.rightLinks {
  display: flex;

  .iconLink {
    margin-right: 15px;
    display: flex;
    flex-direction: column;
    justify-content: center;

    span,
    svg {
      display: block;
    }
  }
}

.menuWrapper {
  display: block;
}

.rightLinks {
  display: flex;
}

:global(.dark-mode) .wrapper {
  background-color: $charcoal;

  // Logo
  [itemprop='logo'] {
    * {
      fill: $white;
      stroke: none !important;
    }

    &:hover {
      * {
        stroke: none !important;
      }
    }
  }

  // Icons
  svg {
    * {
      stroke: $white;
    }

    &:hover {
      * {
        stroke: $light-petrol !important;
      }
    }
  }

  // Text
  * {
    color: $white !important;
    background-color: $charcoal;
  }

  :global(.MainNav__link:hover) {
    span {
      box-shadow: inset 0 -1px 0 0 $white !important;
    }
  }

  :global(.MainNav__item__active span) {
    box-shadow: inset 0 -1px 0 0 $white !important;
  }
}
