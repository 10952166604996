@charset "utf-8";

//
// Variables and mixins that are shared between the global CSS and CSS modules
//
@import '../../../../../scss/functions';
@import '../../../../../scss/config';
@import '../../../../../scss/mixins';
@import '../../../../../scss/vars';
@import '../../../../../scss/media_queries';

.faq {
  border-top: 1px solid $paper;
  display: flex;

  @include max-screen(767px) {
    display: none;
  }

  .sidebar {
    background-color: $mx-color-white;
    width: 33%;
  }

  .faqTitle {
    @include mx-font-title;
    color: $neutral;
    font-size: rem(12);
    letter-spacing: 1px;
    line-height: 1.42;
    padding: 13px 15px 20px;
  }

  .menu {
    list-style-type: none;
  }

  .faqListItem {
    &.active {
      background-color: $paper;
    }

    .faqListButton {
      @include mx-font-body-2;
      color: $charcoal;
      font-size: rem(12);
      line-height: 1.92;
      padding: 11px 15px;
      text-align: left;
    }
  }

  .content {
    background-color: $paper;
    width: 67%;
    height: 330px;
    overflow: auto;
  }

  .faqEntry {
    padding: 20px 30px 10px;
  }

  .faqQuestion {
    @include mx-font-body;
    margin-bottom: 20px;
  }

  .faqAnswer {
    @include mx-font-body-2;
    font-size: rem(13);
    line-height: 1.77;
  }
}
