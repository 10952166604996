@charset "utf-8";

//
// Variables and mixins that are shared between the global CSS and CSS modules
//
@import '../../../../../scss/functions';
@import '../../../../../scss/config';
@import '../../../../../scss/mixins';
@import '../../../../../scss/vars';
@import '../../../../../scss/media_queries';

$logo: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAoAAAAKCAYAAACNMs+9AAAABGdBTUEAALGPC/xhBQAAACBjSFJNAAB6JgAAgIQAAPoAAACA6AAAdTAAAOpgAAA6mAAAF3CculE8AAAACXBIWXMAABYlAAAWJQFJUiTwAAABWWlUWHRYTUw6Y29tLmFkb2JlLnhtcAAAAAAAPHg6eG1wbWV0YSB4bWxuczp4PSJhZG9iZTpuczptZXRhLyIgeDp4bXB0az0iWE1QIENvcmUgNS40LjAiPgogICA8cmRmOlJERiB4bWxuczpyZGY9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkvMDIvMjItcmRmLXN5bnRheC1ucyMiPgogICAgICA8cmRmOkRlc2NyaXB0aW9uIHJkZjphYm91dD0iIgogICAgICAgICAgICB4bWxuczp0aWZmPSJodHRwOi8vbnMuYWRvYmUuY29tL3RpZmYvMS4wLyI+CiAgICAgICAgIDx0aWZmOk9yaWVudGF0aW9uPjE8L3RpZmY6T3JpZW50YXRpb24+CiAgICAgIDwvcmRmOkRlc2NyaXB0aW9uPgogICA8L3JkZjpSREY+CjwveDp4bXBtZXRhPgpMwidZAAABaUlEQVQYGWNgAIJVq0KZQTQIJBx/r+C1+4Wu95YPghARINnQwARUtAqs6PbDVG3rri3bFWZf/BNy8MN/n+3PXvrsfF4PU8wUFhb29/8PBhVx7iv7o/QZPR68/3rv/89vOxkZ/glyS0g0+Ox4Pg+kmBFE/PggOYed/3nyp7c2U/lFDhcAhf/473muxfTr315GRkaG36KSygz//zOw/vwgeOfXJ5HfQLYESOOZM8asIJrh4n8xIJYBMZkYGIwZGJn///v/m5HhG4MhkM/AwHlPCWzTf31GRiAGCTGwMDKe/f3hmsJh5n0Sqo8PMVX/Z/ifwxjG+OtTsK/GJ1bOA0DHMf7/8l0RrPxyuJXy/7vMR3TkRSS+sjLd/vf33wOgvD0vNxfb56/f5vKtXpfC9D80lFl35bG76pYSzt/YmHYCFalws7C4/mf8/+nLt2/NIEVgu0HEf6QA/x4VJP85PFDnf0KAAEzBf2CAAwAGFpFJLaLz2gAAAABJRU5ErkJggg==');

.cf {
  background: #21304a $logo 3px center no-repeat;
  color: #fff;
  display: inline-block;
  font-size: 11px;
  line-height: 18px;
  margin-left: 5px;
  opacity: 0.25;
  padding: 0 5px 0 15px;
  vertical-align: top;

  &:visited {
    color: #fff;
  }

  &:hover,
  &:active {
    color: #4a90e2;
    opacity: 1;
  }
}

.cf__fixed {
  margin-left: 0;
  opacity: 0.9;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 10;
}
