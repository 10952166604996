@charset "utf-8";

//
// Variables and mixins that are shared between the global CSS and CSS modules
//
@import '../../../../../scss/functions';
@import '../../../../../scss/config';
@import '../../../../../scss/mixins';
@import '../../../../../scss/vars';
@import '../../../../../scss/media_queries';

.linkWithBadge {
  display: inline-block;
  line-height: normal;
  position: relative;
  text-align: center;
  white-space: nowrap;

  @include min-screen(1024px) {
    &:hover {
      svg {
        path,
        g {
          stroke: $mx-color-primary;
        }
      }
    }
  }

  &[data-count]:after {
    content: attr(data-count);
    background: $light-peach;
    border-radius: 50%;
    height: rem(18);
    color: $charcoal;
    @include body-font-6;
    left: calc(30px - 12px);
    letter-spacing: normal;
    line-height: rem(18);
    position: absolute;
    text-align: center;
    top: -3px;
    width: rem(18);
  }

  &.animating {
    @include animation(animPulse 200ms linear alternate 4);
  }
}

.icon {
  width: 25px;
  height: 25px;
  vertical-align: middle;
}

@keyframes animPulse {
  0% {
    @include transform(scale(1));
  }

  100% {
    @include transform(scale(1.3));
  }
}

.text {
  text-align: center;
  display: inline-block;
  vertical-align: middle;
  @include mx-font-quote-caption;
  font-size: rem(12);
  line-height: rem(16);
  white-space: normal;
}

.iconContainer {
  @include max-screen(767px) {
    height: 25px;
  }
}
